<template>
    <section>
        <h2>Vertragseditor</h2>
        <div class="contractEditorGrid">
            <contract-control :contract-id="contractId" @statusUpdate="statusUpdate" class="contractControl" ref="contractControl"/>
            <button class="button is-primary addMethodButton" @click="isMethodEditorModalActive = true" v-if="!canceled && !isUser()">Verfahren hinzufügen</button>
            <method-list :contract-id="contractId" ref="methodList" :canceled="canceled" @reloadData="reloadData" class="methodList"/>
        </div>
        <b-modal
        :active.sync="isMethodEditorModalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        v-show="!this.$root.$data.loadingIndicator"
        >
            <method-editor :contract-id="contractId" @reloadMethodList="callLoadData" :canceled="canceled"/>
        </b-modal>

    </section>
</template>

<script>
    import ContractControl from '../components/ContractControl';
    import ContractCreator from '../components/ContractCreator';
    import MethodList from '../components/MethodList';
    import MethodEditor from '../components/MethodEditor';
    import {Modal} from 'buefy';

    export default {
        name: "Contract",
        components: {ContractControl, ContractCreator, MethodList, Modal, MethodEditor},
        props: ['contractId'],
        data: function() {
            return {
                isMethodEditorModalActive: false,
                isContractCreatorActive: false,
                canceled: false,
                isModalVisible: false,
            }
        },
        methods: {
            /**
             * Trigger data load on the child component.
             */
            callLoadData()
            {
                this.$refs.methodList.loadData();
            },
            /**
             * Check if contract canceled.
             * @param newState
             */
            statusUpdate(newState)
            {
                this.canceled = (newState === 'canceled');
            },
            /**
             * Reload data on the child component.
             */
            reloadData()
            {
                this.$refs['contractControl'].getData();
            }
        },
    }
</script>

<style scoped lang="scss">
    .contractEditorGrid {
        display: grid;
        grid-template-columns: 1fr 3fr;
        .contractControl {
            grid-row: span 2;
        }
        .addMethodButton {
            margin-bottom: 1rem;
        }
    }
    .addMethodButton {
        justify-self: right;
        grid-column: 2;
    }
</style>
