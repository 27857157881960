<template>
    <section>
        <h2>Kundenliste</h2>
        <button class="button is-primary addMethodButton" @click="isClientCreatorModalActive = true">Kunden hinzufügen</button>
        <b-table
            :data="clients"
            :paginated="total > perPage"
            backend-pagination
            backend-sorting
            backend-filtering
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            :current-page="currentPage"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            custom-row-key="id"
            :loading="isLoading"
        >
            <template slot-scope="props">
                <b-table-column field="name" label="Name">
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column label="Aktionen">
                    <b-button @click="openEditorModal(props.row.id)"><b-icon icon="edit"/></b-button>
                    <b-button @click="openDeleteDialog(props.row.id)" type="is-danger"><b-icon icon="trash"/></b-button>
                </b-table-column>
            </template>
        </b-table>
        <b-modal
            :active.sync="isClientEditorModalActive"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
            v-show="!this.$root.$data.loadingIndicator"
        >
            <client-editor :client-id="selectedClient" @loadData="loadData"/>
        </b-modal>
        <b-modal
            :active.sync="isClientCreatorModalActive"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
            v-show="!this.$root.$data.loadingIndicator"
        >
            <client-creator @loadData="loadData"/>
        </b-modal>
    </section>
</template>

<script>
    import {EventBus} from '../../eventbus';
    import ClientEditor from './ClientEditor';
    import ClientCreator from './ClientCreator';

    export default {
        name: 'ClientList',
        components: {ClientCreator, ClientEditor},
        data() {
            return {
                clients: [],
                currentPage: 1,
                total: 0,
                perPage: 10,
                isLoading: true,
                selectedClient: null,
                isClientEditorModalActive: false,
                isClientCreatorModalActive: false,
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            /**
             * Load data.
             *
             * @param resetPage
             */
            loadData(resetPage = true)
            {
                if(resetPage) {
                    this.currentPage = 1;
                }
                this.isLoading = true;
                axios.all([this.getGetClientsRequest()]).then(axios.spread((clients) => {
                    this.total = clients.total;
                    this.$set(this, 'clients', clients.data.data);
                })).finally(() => {
                    this.isLoading = false;
                });
            },
            /**
             * Page change handler.
             *
             * @param page
             */
            onPageChange(page)
            {
                this.currentPage = page;
                this.loadData(false);
            },
            /**
             * Get request to load clients.
             *
             * @returns {*}
             */
            getGetClientsRequest()
            {
                return axios.get('/admin/get/clients?page=' + this.currentPage);
            },
            /**
             * Open editor modal handler.
             *
             * @param id
             */
            openEditorModal(id)
            {
                this.selectedClient = id;
                this.isClientEditorModalActive = true;
            },
            /**
             * Open delete dialog handler.
             *
             * @param id
             */
            openDeleteDialog(id)
            {
                this.$buefy.dialog.confirm({
                    title: 'Kunden löschen',
                    message: 'Sind Sie sicher, dass Sie den Kunden löschen möchten? Dies löscht ebenfalls all seine Standorte, Verträge und Benutzer.',
                    confirmText: 'Kunden löschen',
                    cancelText: 'Abbrechen',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        EventBus.$emit('setLoadingIndicator');
                        axios.get('/admin/delete/client/' + id).then((response) => {
                            if(response.data.error) {
                                return;
                            }
                            this.loadData();
                            this.$buefy.notification.open({
                                message: 'Der Kunde wurde gelöscht.',
                                type: 'is-success',
                            });
                            EventBus.$emit('unsetLoadingIndicator');
                        }).finally(() => {
                            EventBus.$emit('unsetLoadingIndicator');
                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
