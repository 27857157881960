<template>
    <div class="methodLocationList__data">
        <b-field>
            <b-select v-model="myMethodLocation.location" placeholder="Location" required v-if="editMode"
                      ref="location">
                <option>Personenstammdaten</option>
                <option>Kommunikationsdaten</option>
                <option>Vertragsstammdaten</option>
                <option>Kundenhistorie</option>
                <option>Vertragsabrechnungs- und Zahlungsdaten</option>
                <option>Planungs- und Steuerungsdaten</option>
                <option>Auskunftsangaben</option>
            </b-select>
            <label v-else>{{ myMethodLocation.location }}</label>
        </b-field>
        <b-field>
            <b-select v-model="myMethodLocation.type" placeholder="Type" required v-if="editMode" ref="type">
                <option>Kunden</option>
                <option>Interessenten</option>
                <option>Abonnenten</option>
                <option>Beschäftigte</option>
                <option>Auszubildende</option>
                <option>Bewerber</option>
                <option>Lieferanten</option>
                <option>Handelsvertreter</option>
                <option>Ansprechpartner</option>
            </b-select>
            <label v-else>{{ myMethodLocation.type }}</label>
        </b-field>
    </div>
</template>

<script>
export default {
    name: 'MethodLocationElement',
    props: ['methodLocation', 'canEdit'],
    data() {
        return {
            myMethodLocation: _.cloneDeep(this.methodLocation),
            editMode: false,
        }
    },
    mounted() {
        if (this.canEdit) {
            this.editMode = this.canEdit;
        }
    },
    watch: {
        myMethodLocation: {
            handler: function (newValue, oldValue) {
                this.$emit('input', newValue);
            },
            deep: true,
        }
    },
    methods: {
        validate() {
            if (this.editMode) {
                if (!this.$refs['location'].checkHtml5Validity()) {
                    return false;
                }
                if (!this.$refs['type'].checkHtml5Validity()) {
                    return false;
                }
            }

            return true;
        }
    }
}
</script>
