var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "user-editor" },
    [
      _vm.loaded
        ? [
            _vm.user.id
              ? _c("h2", [_vm._v("Benutzer bearbeiten")])
              : _c("h2", [_vm._v("Benutzer anlegen")]),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Name" } },
              [
                _c("b-input", {
                  ref: "userName",
                  attrs: { required: "", "use-html5-validation": "" },
                  model: {
                    value: _vm.user.name,
                    callback: function($$v) {
                      _vm.$set(_vm.user, "name", $$v)
                    },
                    expression: "user.name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Email" } },
              [
                _c("b-input", {
                  ref: "userEmail",
                  attrs: {
                    type: "email",
                    required: "",
                    "use-html5-validation": ""
                  },
                  model: {
                    value: _vm.user.email,
                    callback: function($$v) {
                      _vm.$set(_vm.user, "email", $$v)
                    },
                    expression: "user.email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Rolle" } },
              [
                _c(
                  "b-select",
                  {
                    ref: "userRole",
                    attrs: {
                      placeholder: "Rolle",
                      expanded: "",
                      required: "",
                      "use-html5-validation": ""
                    },
                    on: { input: _vm.onRoleSelect },
                    model: {
                      value: _vm.selectedRole,
                      callback: function($$v) {
                        _vm.selectedRole = $$v
                      },
                      expression: "selectedRole"
                    }
                  },
                  _vm._l(_vm.possibleRoles, function(role) {
                    return _c("option", { domProps: { value: role } }, [
                      _vm._v(_vm._s(role))
                    ])
                  }),
                  0
                )
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isSuperadmin
              ? _c(
                  "b-field",
                  { attrs: { label: "Standort" } },
                  [
                    _c(
                      "b-select",
                      {
                        ref: "userLocation",
                        attrs: {
                          placeholder: "Standort",
                          expanded: "",
                          required: "",
                          "use-html5-validation": ""
                        },
                        on: { input: _vm.onLocationSelect },
                        model: {
                          value: _vm.selectedLocation,
                          callback: function($$v) {
                            _vm.selectedLocation = $$v
                          },
                          expression: "selectedLocation"
                        }
                      },
                      _vm._l(_vm.possibleLocations, function(location) {
                        return _c(
                          "option",
                          { domProps: { value: location.id } },
                          [_vm._v(_vm._s(location.name))]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.user.id
              ? _c(
                  "b-button",
                  {
                    staticClass: "is-fullwidth",
                    on: { click: _vm.resetPassword }
                  },
                  [_vm._v("Passwort zurücksetzen")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "is-fullwidth",
                attrs: { type: "is-primary" },
                on: { click: _vm.onSaveButtonClick }
              },
              [_vm._v("Speichern")]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }