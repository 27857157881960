var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "contractCreator" },
    [
      _c("h2", { staticClass: "full-row has-margin-bottom-md" }, [
        _vm._v("Neuen Vertrag anlegen")
      ]),
      _vm._v(" "),
      _c(
        "b-field",
        { staticClass: "grid-col-1", attrs: { label: "Auftraggeber" } },
        [
          _c(
            "b-select",
            {
              attrs: { placeholder: "Auftraggeber...", expanded: "" },
              on: { input: _vm.loadContractors },
              model: {
                value: _vm.selectedCustomer,
                callback: function($$v) {
                  _vm.selectedCustomer = $$v
                },
                expression: "selectedCustomer"
              }
            },
            _vm._l(_vm.customers, function(customer) {
              return _c(
                "option",
                { key: customer.id, domProps: { value: customer.id } },
                [_vm._v(_vm._s(customer.name))]
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-field",
        { staticClass: "grid-col-3", attrs: { label: "Auftragnehmer" } },
        [
          _c(
            "b-select",
            {
              attrs: {
                placeholder: "Auftragnehmer...",
                disabled: !_vm.contractors.length,
                expanded: ""
              },
              model: {
                value: _vm.selectedContractor,
                callback: function($$v) {
                  _vm.selectedContractor = $$v
                },
                expression: "selectedContractor"
              }
            },
            _vm._l(_vm.contractors, function(contractor) {
              return _c(
                "option",
                { key: contractor.id, domProps: { value: contractor.id } },
                [_vm._v(_vm._s(contractor.name))]
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.noMoreContractors
        ? _c(
            "p",
            { staticClass: "full-row has-margin-bottom-md has-text-danger" },
            [
              _vm._v(
                "\n        Dieser Auftraggeber hat bereits Verträge mit allen verfügbaren Auftragnehmern geschlossen.\n    "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticClass: "grid-col-1 has-margin-bottom-sm",
          attrs: { label: "Subs" }
        },
        [
          _c(
            "ul",
            _vm._l(_vm.subs, function(sub) {
              return _c(
                "li",
                [
                  _c(
                    "b-checkbox",
                    {
                      key: sub.id,
                      model: {
                        value: _vm.selectedSubs[sub.id],
                        callback: function($$v) {
                          _vm.$set(_vm.selectedSubs, sub.id, $$v)
                        },
                        expression: "selectedSubs[sub.id]"
                      }
                    },
                    [_vm._v(_vm._s(sub.name))]
                  )
                ],
                1
              )
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { staticClass: "grid-col-3", attrs: { label: "Gültig ab" } },
        [
          _c("b-datepicker", {
            model: {
              value: _vm.date_valid_from,
              callback: function($$v) {
                _vm.date_valid_from = $$v
              },
              expression: "date_valid_from"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-row" },
        [
          _c(
            "b-button",
            {
              attrs: {
                type: "is-primary",
                disabled: !(_vm.selectedCustomer && _vm.selectedContractor)
              },
              on: { click: _vm.createContract }
            },
            [_vm._v("Speichern")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "grid-col-2 has-padding-none arrow",
        staticStyle: { "align-self": "flex-end" }
      },
      [_c("i", { staticClass: "fas fa-long-arrow-alt-right" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }