var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "method-editor" },
    [
      _vm.loaded
        ? [
            _c("h2", { staticClass: "full-row" }, [
              _vm._v("Verfahren: " + _vm._s(_vm.method.title))
            ]),
            _vm._v(" "),
            _c(
              "b-field",
              { staticClass: "grid-col-1", attrs: { label: "Auftraggeber" } },
              [_c("p", [_vm._v(_vm._s(_vm.method.customer.name))])]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "b-field",
              { staticClass: "grid-col-3", attrs: { label: "Auftragnehmer" } },
              [_c("p", [_vm._v(_vm._s(_vm.method.contractor.name))])]
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { staticClass: "full-row", attrs: { label: "Titel" } },
              [
                _c("b-input", {
                  ref: "methodTitleInput",
                  attrs: { required: "", readonly: _vm.cannotEdit },
                  model: {
                    value: _vm.method.title,
                    callback: function($$v) {
                      _vm.$set(_vm.method, "title", $$v)
                    },
                    expression: "method.title"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { staticClass: "full-row", attrs: { label: "Beschreibung" } },
              [
                _c("b-input", {
                  ref: "methodDescriptionInput",
                  attrs: {
                    type: "textarea",
                    required: "",
                    readonly: _vm.cannotEdit
                  },
                  model: {
                    value: _vm.method.description,
                    callback: function($$v) {
                      _vm.$set(_vm.method, "description", $$v)
                    },
                    expression: "method.description"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "full-row is-flex is-flex-col",
                attrs: {
                  label: "Gültigkeit",
                  message: "(Ablaufdatum optional)"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "sub-grid" },
                  [
                    _c("b-datepicker", {
                      ref: "methodStartDateInput",
                      attrs: {
                        required: "",
                        disabled: _vm.cannotEdit,
                        expanded: ""
                      },
                      model: {
                        value: _vm.method.date_valid_from,
                        callback: function($$v) {
                          _vm.$set(_vm.method, "date_valid_from", $$v)
                        },
                        expression: "method.date_valid_from"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "align-self-center" }, [
                      _vm._v("bis")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "is-relative" },
                      [
                        _c("b-datepicker", {
                          ref: "methodEndDateInput",
                          attrs: { disabled: _vm.cannotEdit, expanded: "" },
                          model: {
                            value: _vm.method.date_valid_to,
                            callback: function($$v) {
                              _vm.$set(_vm.method, "date_valid_to", $$v)
                            },
                            expression: "method.date_valid_to"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "date-remove" }, [
                          !_vm.cannotEdit && _vm.method.date_valid_to
                            ? _c("i", {
                                staticClass: "fas fa-times-circle",
                                attrs: { title: "Enddatum löschen" },
                                on: {
                                  click: function($event) {
                                    _vm.method.date_valid_to = null
                                  }
                                }
                              })
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "full-row",
                attrs: { label: "Ort und Art der Datenspeicherung" }
              },
              [
                _c("method-locations", {
                  ref: "methodLocationInput",
                  attrs: {
                    "method-locations": _vm.method.method_locations,
                    cannotEdit: _vm.cannotEdit
                  },
                  model: {
                    value: _vm.method.method_locations,
                    callback: function($$v) {
                      _vm.$set(_vm.method, "method_locations", $$v)
                    },
                    expression: "method.method_locations"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "full-row",
                attrs: { label: "Sicherheitsmaßnahmen" }
              },
              [
                _c("b-input", {
                  ref: "methodTOMInput",
                  attrs: {
                    type: "textarea",
                    required: "",
                    readonly: _vm.cannotEdit
                  },
                  model: {
                    value: _vm.method.tom,
                    callback: function($$v) {
                      _vm.$set(_vm.method, "tom", $$v)
                    },
                    expression: "method.tom"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "full-row",
                attrs: { label: "Personen und Rollen" }
              },
              [
                _c("method-users", {
                  ref: "methodUserInput",
                  attrs: {
                    "method-users": _vm.method.method_users,
                    cannotEdit: _vm.cannotEdit
                  },
                  model: {
                    value: _vm.method.method_users,
                    callback: function($$v) {
                      _vm.$set(_vm.method, "method_users", $$v)
                    },
                    expression: "method.method_users"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { staticClass: "full-row", attrs: { label: "Subs" } },
              [
                _c("sub-list", {
                  attrs: {
                    "subs-in-method": _vm.method.subs_in_method,
                    "subs-in-contract": _vm.method.subs_in_contract,
                    disabled: _vm.cannotEdit
                  },
                  on: {
                    "update:subsInContract": function($event) {
                      _vm.method.subs_in_contract = $event
                    }
                  },
                  model: {
                    value: _vm.method.subs_in_method,
                    callback: function($$v) {
                      _vm.$set(_vm.method, "subs_in_method", $$v)
                    },
                    expression: "method.subs_in_method"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "full-row is-flex justify-between" },
              [
                !_vm.cannotEdit
                  ? [
                      _c(
                        "b-tooltip",
                        {
                          staticClass: "is-primary",
                          attrs: {
                            multilined: "",
                            label:
                              'Durch das Speichern von Änderungen wird das Verfahren automatisch auf den Status "offen" gesetzt und muss vom zuständigen Beauftragen freigeschaltet werden.'
                          }
                        },
                        [
                          !_vm.cannotEdit
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "has-margin-right-sm",
                                  attrs: { type: "is-primary" },
                                  on: { click: _vm.saveOrCreateMethod }
                                },
                                [
                                  _vm._v(
                                    "\n                        Speichern\n                    "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                (_vm.isAdmin() || _vm.isSuperAdmin()) && !_vm.canceled
                  ? [
                      _c(
                        "div",
                        [
                          _vm.isAdmin || _vm.isSuperAdmin
                            ? _c(
                                "b-button",
                                {
                                  staticClass:
                                    "has-margin-right-sm is-outlined",
                                  attrs: { type: "is-danger" },
                                  on: { click: _vm.deleteMethod }
                                },
                                [
                                  _vm._v(
                                    "Verfahren\n                        löschen\n                    "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.method.id && (_vm.isAdmin || _vm.isSuperAdmin)
                            ? [
                                _vm.method.status === "open"
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "has-margin-right-sm",
                                        attrs: { type: "is-outlined" },
                                        on: { click: _vm.approveMethod }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Verfahren freigeben\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.method.status === "active"
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: { type: "is-outlined" },
                                        on: { click: _vm.disapproveMethod }
                                      },
                                      [
                                        _vm._v(
                                          "Verfahren\n                            zurückziehen\n                        "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "grid-col-2 has-padding-none arrow",
        staticStyle: { "align-self": "flex-end" }
      },
      [_c("i", { staticClass: "fas fa-long-arrow-alt-right" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }