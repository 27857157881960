<template>
    <div class="subList">
        <div v-for="(subInMethod, index) in mySubsInMethod" class="subList__inMethodRow">
            <p>{{subInMethod.name}}</p>
            <i class="fas fa-trash-alt" @click="removeFromMethod(index)" v-if="!editDisabled"></i>
        </div>
        <div class="subList__subSelect" v-if="!addDisabled">
            <b-field>
                <b-select placeholder="Hinzufügen..." v-model="selectedSubToAdd" :disabled="addDisabled" @input="addToMethod" icon="building">
                    <option :value="-1" selected :key="-1">Hinzufügen...</option>
                    <option v-for="(subInContract, index) in mySubsInContract" :value="index" :key="subInContract.id">{{subInContract.name}}</option>
                </b-select>
            </b-field>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SubList',
        props: ['subsInContract', 'subsInMethod'],
        data() {
            return {
                mySubsInContract: _.cloneDeep(this.subsInContract),
                mySubsInMethod: _.cloneDeep(this.subsInMethod),
                selectedSubToAdd: null,
            }
        },
        watch: {
            mySubsInMethod: {
                handler: function(newValue, oldValue) {
                    this.$emit('input', newValue);
                }
            },
            mySubsInContract: {
                handler: function(newValue, oldValue) {
                    this.$emit('update:subsInContract', newValue);
                }
            }
        },
        computed: {
            addDisabled() {
                if(!this.mySubsInContract.length || this.isUser()) {
                    return true;
                }
            },
            editDisabled()
            {
                if(this.isUser()) {
                    return true;
                }
            }
        },
        methods: {
            /**
             * Add the selected sublocation to the method.
             */
            addToMethod() {
                if(this.selectedSubToAdd === null || this.selectedSubToAdd === -1 || this.selectedSubToAdd === undefined) {
                    return;
                }
                const selectedIndex = this.selectedSubToAdd;
                let removedArray = this.mySubsInContract.splice(selectedIndex, 1);
                this.mySubsInMethod = this.mySubsInMethod.concat(removedArray);
                this.$set(this, 'selectedSubToAdd', -1);
            },
            /**
             * Remove the selected sublocation from the method.
             *
             * @param index
             */
            removeFromMethod(index) {
                let removedArray = this.mySubsInMethod.splice(index, 1);
                this.$set(this, 'mySubsInContract', this.mySubsInContract.concat(removedArray));
                this.$set(this, 'selectedSubToAdd', -1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .subList {
        &__inMethodRow {
            display: grid;
            grid-template-columns: 1fr 30px;
            align-items: center;
            margin: 5px 0;
            padding: 15px 0;
            border-bottom: 1px solid #D5D9DE;
            &:last-child {
                border-bottom: unset;
            }
            i.fas {
                cursor: pointer;
            }
        }
        &__subSelect {
            display: flex;
            align-items: center;
            .field {
                margin-bottom:0;
                margin-right: 2rem;
            }
        }
    }
</style>
