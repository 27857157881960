<template>
    <div class="methodUserList__data">
        <b-field>
            <b-input v-model="myMethodUser.username" placeholder="Name" required v-if="editMode" ref="username"/>
            <label v-else>{{myMethodUser.username}}</label>
        </b-field>
        <b-field>
            <b-input type="email" v-model="myMethodUser.email" placeholder="Email" required v-if="editMode" ref="email"/>
            <label v-else>{{myMethodUser.email}}</label>
        </b-field>
        <b-field>
            <b-input v-model="myMethodUser.company_role" placeholder="Rolle" required v-if="editMode" ref="role"/>
            <label v-else>{{myMethodUser.company_role}}</label>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'MethodUserElement',
        props: ['methodUser', 'canEdit'],
        data() {
            return {
                myMethodUser: _.cloneDeep(this.methodUser),
                editMode: false,
            }
        },
        mounted() {
            if(this.canEdit) {
                this.editMode = this.canEdit;
            }
        },
        watch: {
            myMethodUser: {
                handler: function(newValue, oldValue) {
                    this.$emit('input', newValue);
                },
                deep: true,
            }
        },
        methods: {
            validate()
            {
                if(this.editMode) {
                    if(!this.$refs['username'].checkHtml5Validity()) {
                        return false;
                    }
                    if(!this.$refs['email'].checkHtml5Validity()) {
                        return false;
                    }
                    if(!this.$refs['role'].checkHtml5Validity()) {
                        return false;
                    }
                }

                return true;
            }
        }
    }
</script>
