<template>
    <div class="methodUserList">
        <div class="methodUserList__head">
            <b-field label="Name"/>
            <b-field label="Email"/>
            <b-field label="Rolle"/>
        </div>
        <div class="methodUserList__row" v-for="(methodUser, index) in myMethodUsers">
            <method-user-element :key="methodUser.id" :method-user.sync="myMethodUsers[index]" v-model="myMethodUsers[index]" :can-edit="editMode.includes(index)" :ref="'mu-' + index" />
            <div class="methodUserList__action">
                <span class="cursor-pointer">
                <i class="fas fa-edit col3" @click="setEditMode(index)" v-show="!editMode.includes(index) && !cannotEdit"></i>
            </span>
                <span class="cursor-pointer">
                <i class="fas fa-trash-alt col4" @click="deleteMethodUser(index)" v-if="!cannotEdit && myMethodUsers.length > 1"></i>
            </span>
            </div>
        </div>
        <button class="button has-margin-top-md" @click="addMethodUser" :disabled="cannotEdit" v-if="!cannotEdit"><b-icon icon="user-plus"/></button>
    </div>
</template>

<script>
    import MethodUserElement from "./MethodUserElement";
    export default {
        name: 'MethodUsers',
        components: {MethodUserElement},
        props: ['methodUsers', 'cannotEdit'],
        data() {
            return {
                myMethodUsers: _.cloneDeep(this.methodUsers),
                editMode: []
            }
        },
        mounted() {
            if(this.myMethodUsers.length === 1 && !this.myMethodUsers[0].hasOwnProperty('id')) {
                this.setEditMode(0);
            }
        },
        watch: {
            myMethodUsers: {
                handler: function(newValue, oldValue) {
                    this.$emit('input', newValue);
                },
                deep: true,
            }
        },
        methods: {
            /**
             * Add a new method user.
             */
            addMethodUser() {
                this.myMethodUsers.push({username: '', email: '', company_role: ''});
                this.editMode.push(this.myMethodUsers.length - 1);
            },
            /**
             * Delete the given method user.
             *
             * @param index
             */
            deleteMethodUser(index) {
                this.$delete(this.myMethodUsers, index);
                this.$delete(this.editMode, index);
            },
            /**
             * Enable edit mode for the selected method user.
             *
             * @param index
             */
            setEditMode(index)
            {
                this.$refs['mu-'+index][0].editMode = true;
                this.editMode.push(index);
            },
            /**
             * Check if the given button hidden.
             *
             * @param index
             * @returns {*}
             */
            isHidden(index)
            {
                return this.hiddenEditButtons.includes(index);
            },
            validate()
            {
                let index = 0;
                for(index; index < this.myMethodUsers.length; index++) {
                    if(!this.$refs['mu-' + index][0].validate()) {
                        return false;
                    }
                }
                return true;
            }
        }
    }
</script>
