var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("Vertragseditor")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "contractEditorGrid" },
        [
          _c("contract-control", {
            ref: "contractControl",
            staticClass: "contractControl",
            attrs: { "contract-id": _vm.contractId },
            on: { statusUpdate: _vm.statusUpdate }
          }),
          _vm._v(" "),
          !_vm.canceled && !_vm.isUser()
            ? _c(
                "button",
                {
                  staticClass: "button is-primary addMethodButton",
                  on: {
                    click: function($event) {
                      _vm.isMethodEditorModalActive = true
                    }
                  }
                },
                [_vm._v("Verfahren hinzufügen")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("method-list", {
            ref: "methodList",
            staticClass: "methodList",
            attrs: { "contract-id": _vm.contractId, canceled: _vm.canceled },
            on: { reloadData: _vm.reloadData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$root.$data.loadingIndicator,
              expression: "!this.$root.$data.loadingIndicator"
            }
          ],
          attrs: {
            active: _vm.isMethodEditorModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isMethodEditorModalActive = $event
            }
          }
        },
        [
          _c("method-editor", {
            attrs: { "contract-id": _vm.contractId, canceled: _vm.canceled },
            on: { reloadMethodList: _vm.callLoadData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }