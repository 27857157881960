<template>
    <section class='is-grid gap-2'>
        <template v-if='contract'>
            <div>
                <h3 class="has-margin-bottom-xs">Auftraggeber</h3>
                <p>{{contract.customer.name}}</p>
            </div>
            <div>
                <h3 class="has-margin-bottom-xs">Auftragnehmer</h3>
                <p>{{contract.contractor.name}}</p>
            </div>
            <div>
                <h3 class="has-margin-bottom-xs">Vertragsbeginn</h3>
                <b-datepicker
                    v-model='startDate'
                    class='contractControl__datepicker'
                    :disabled="isContractDisabled"
                    :date-formatter="formatDate"
                    @input="updateContract"
                />
            </div>
            <div>
                <h3 class="has-margin-bottom-xs">Auswählbare Subs</h3>
                <ul>
                    <li v-for='(sub, index) in subsInLocation'>
                        <b-checkbox
                            v-model='subsInContract[sub.id]'
                            :disabled='isSublocationDisabled(index)'
                            @input="updateContract"
                        >{{sub.name}}</b-checkbox>
                    </li>
                </ul>
            </div>
            <div>
                <h3 class="has-margin-bottom-xs">Anhänge</h3>
                <div class='contractControl__attachments'>
                    <p><a :href='"/contract/generatepdf/" + this.contractId + "?type=a"'><i class='fas fa-paperclip'></i> Anhang A drucken</a></p>
                    <p><a :href='"/contract/generatepdf/" + this.contractId + "?type=b"'><i class='fas fa-paperclip'></i> Anhang B drucken</a></p>
                </div>
                <div class='contractControl__buttons'>
                    <b-button
                        type='is-danger'
                        outlined
                        @click='openCancelContractDialog'
                        v-if='!isContractDisabled'
                    >Vertrag beenden</b-button>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
    import {Button, Checkbox, Datepicker} from 'buefy';
    import {EventBus} from "../eventbus";

    export default {
        name: 'ContractControl',
        data: function() {
            return {
                contract: null,
                startDate: null,
                subsInLocation: [],
                subsInContract: [],
            }
        },
        components: {Button, Checkbox, Datepicker},
        props: ['contractId'],
        created() {
            this.getData();
        },
        computed: {
            /**
             * Checks if the contract is disabled for editing.
             *
             * @returns {boolean|*}
             */
            isContractDisabled() {
                return ('canceled' === this.contract.status) || this.isUser();
            },
        },
        methods: {
            /**
             * Returns a formatted date.
             *
             * @param date
             * @returns {string}
             */
            formatDate(date) {
                return date.toLocaleDateString('de-DE',{day: '2-digit', month: '2-digit', year: 'numeric'});
            },
            /**
             * Get the contract request.
             *
             * @returns {*}
             */
            getContract() {
                return axios.get('/contract/get/' + this.contractId);
            },
            /**
             * Get the sublocation request.
             *
             * @returns {*}
             */
            getSubs() {
                return axios.get('/location/subs');
            },
            /**
             * Get data from the backend.
             */
            getData() {
                axios.all([this.getContract(), this.getSubs()]).then(axios.spread((contract, subs) => {
                    if(!contract.data.error && !subs.data.error) {
                        this.$set(this, 'contract', contract.data);
                        this.$emit('statusUpdate', this.contract.status);
                        this.subsInLocation = [];
                        this.subsInContract = [];
                        subs.data.forEach((sub) => {
                            this.subsInLocation.push(sub);
                            this.subsInContract[sub.id] = false;
                        });
                        this.contract.subs.forEach((sub) => {
                            this.subsInContract[sub.id] = true;
                        });
                        this.startDate = new Date(this.contract.start_date);
                    }
                }));
            },
            /**
             * Return the list of newly checked sublocations in the contract on save.
             *
             * @returns {T}
             */
            getNewSubsInContract() {
                return this.subsInContract.reduce((accumulator, arrayElement, index) => {
                    if(arrayElement === true) {
                        accumulator.push(index);
                    }
                    return accumulator;
                }, []);
            },
            /**
             * Handle the cancel contract dialog.
             */
            openCancelContractDialog() {
                this.$buefy.dialog.confirm({
                    title: 'Vertrag beenden',
                    message: 'Sind Sie sicher, dass Sie den Vertrag beenden möchen? Dies macht alle aktuell laufenden Verfahren hinfällig.',
                    confirmText: 'Vertrag beenden',
                    cancelText: 'Abbrechen',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        EventBus.$emit('setLoadingIndicator');
                        axios.patch('/contract/cancel/' + this.contract.id).then((response) => {
                            if(200 === response.status) {
                                this.contract.status = 'canceled';
                                this.$emit('statusUpdate', this.contract.status);
                                this.$buefy.toast.open({
                                    message: 'Der Vertrag wurde beendet.',
                                });
                                EventBus.$emit('unsetLoadingIndicator');
                            }
                        }).finally(() => {
                            EventBus.$emit('unsetLoadingIndicator');
                        });
                    }
                });
            },
            /**
             * Update contract data.
             */
            updateContract() {
                EventBus.$emit('setLoadingIndicator');
                axios.patch('/contract/update/' + this.contract.id, {subs: this.getNewSubsInContract(), start_date: this.startDate.toDateString()}).then((response) => {
                    if(response.data.error) {
                        return;
                    }
                    this.getData();
                    // this.$buefy.notification.open({
                    //     message: 'Der Vertrag wurde erfolgreich gespeichert.',
                    //     type: 'is-success',
                    //     position: 'is-top-right',
                    //     duration: 3500
                    // });
                }).finally(() => {
                    EventBus.$emit('unsetLoadingIndicator');
                })
            },
            /**
             * Checks if sublocation is disabled.
             * It is disbaled for checking or unchecking when it is set for an active method.
             *
             * @param index
             * @returns {boolean}
             */
            isSublocationDisabled(index) {
                if(this.subsInLocation[index].methods_as_sub.length) {
                    for(const method of this.subsInLocation[index].methods_as_sub) {
                        if(method.status === 'active' && method.contract_id === this.contract.id) {
                            return true;
                        }
                    }
                }
                // let inContract = this.contract.subs.filter(sub => sub.id === subId);
                // console.log(inContract);
                // if(this.subsInContract[subId] && this.contract.active_method_count && inContract.length) {
                //     return true;
                // }
                return !!this.isContractDisabled;

            }
        }
    }
</script>
