<template>
    <section class="is-grid gap-2">
        <h2>Benutzerprofil</h2>
        <b-field label="Name">
            <b-input
                v-model="name"
                required
                ref="nameInput"
            />
        </b-field>
        <b-field label="Email">
            <b-input
                v-model="email"
                type="email"
                required
                ref="emailInput"
            />
        </b-field>
        <b-field label="Altes Passwort">
            <b-input
                v-model="oldPassword"
                type="password"
                ref="oldPasswordInput"
            />
        </b-field>
        <b-field label="Neues Passwort">
            <b-input
                v-model="newPassword"
                type="password"
                ref="newPasswordInput"
            />
        </b-field>
        <b-field label="Neues Passwort (Wiederholung)">
            <b-input
                v-model="newPasswordConfirm"
                type="password"
                ref="newPasswordConfirmInput"
            />
        </b-field>
        <b-field>
            <b-button type="is-primary" @click="onSaveButtonClick">Speichern</b-button>
        </b-field>
    </section>
</template>

<script>
    import validations from "../mixins/validations";

    export default {
        name: 'ManageProfile',
        mixins: [validations],
        data() {
            return {
                name: '',
                email: '',
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            }
        },
        created() {
            this.name = this.$root.$data.name;
            this.email = this.$root.$data.username;
        },
        methods: {
            onSaveButtonClick()
            {
                if(!this.name.length) {
                    this.$refs['nameInput'].setInvalid();
                    return;
                }
                if(!this.email.length) {
                    this.$refs['emailInput'].setInvalid();
                    return;
                }

                if(this.oldPassword.length || this.newPassword.length || this.newPasswordConfirm.length) {

                    if(!(this.oldPassword.length && this.newPassword.length && this.newPasswordConfirm.length)) {
                        if(!this.oldPassword.length) {
                            this.$refs['oldPasswordInput'].validationMessage = 'Bitte geben Sie ihr altes Passwort ein.';
                            this.$refs['oldPasswordInput'].setInvalid();
                        }
                        if(!this.newPassword.length) {
                            this.$refs['newPasswordInput'].validationMessage = 'Bitte geben Sie ihr neues Passwort ein.';
                            this.$refs['newPasswordInput'].setInvalid();
                        }
                        if(!this.newPasswordConfirm.length) {
                            this.$refs['newPasswordConfirmInput'].validationMessage = 'Bitte wiederholen Sie ihr neues Passwort.';
                            this.$refs['newPasswordConfirmInput'].setInvalid();
                        }
                        return;
                    }

                    if(this.newPassword !== this.newPasswordConfirm) {
                        this.$refs['newPasswordInput'].validationMessage = 'Bitte stellen Sie sicher, dass die Passwörter übereinstimmen.';
                        this.$refs['newPasswordInput'].setInvalid();
                        this.$refs['newPasswordConfirmInput'].validationMessage = 'Bitte stellen Sie sicher, dass die Passwörter übereinstimmen.';
                        this.$refs['newPasswordConfirmInput'].setInvalid();
                        return;
                    }
                }

                axios.post('/user/updateSelf', {
                    name: this.name,
                    email: this.email,
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                    newPassword_confirmation: this.newPasswordConfirm,
                }).then((response) => {
                    if(response.error) {
                        return;
                    }
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Das Benutzerprofil wurde erfolgreich geändert.'
                    })
                })
            }
        }
    }
</script>
