var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        {
          staticClass:
            "is-flex items-center justify-between has-margin-bottom-sm"
        },
        [
          _c("h3", { staticClass: "has-margin-none" }, [
            _vm._v("Aktuelle Verfahren (" + _vm._s(_vm.total) + ")")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "is-flex" },
            [
              _c(
                "b-select",
                {
                  staticClass: "has-margin-right-md",
                  attrs: { placeholder: "Filter", icon: "filter" },
                  on: { input: _vm.loadData },
                  model: {
                    value: _vm.filter,
                    callback: function($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter"
                  }
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("Alle")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "active" } }, [
                    _vm._v("Aktiv")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "open" } }, [_vm._v("Offen")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "expired" } }, [
                    _vm._v("Abgelaufen")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "soon" } }, [
                    _vm._v("Bald Abgelaufen")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-select",
                {
                  attrs: {
                    placeholder: "Sortierung",
                    icon: "sort-amount-down"
                  },
                  on: { input: _vm.loadData },
                  model: {
                    value: _vm.sort,
                    callback: function($$v) {
                      _vm.sort = $$v
                    },
                    expression: "sort"
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "title_asc", selected: "" } },
                    [_vm._v("Name aufsteigend")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "title_desc" } }, [
                    _vm._v("Name absteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "date_valid_from_asc" } }, [
                    _vm._v("Startdatum aufsteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "date_valid_from_desc" } }, [
                    _vm._v("Startdatum absteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "date_valid_to_asc" } }, [
                    _vm._v("Ablaufdatum aufsteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "date_valid_to_desc" } }, [
                    _vm._v("Ablaufdatum absteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "status_asc" } }, [
                    _vm._v("Status")
                  ])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.data,
            paginated: _vm.total > _vm.perPage,
            "backend-pagination": "",
            "backend-sorting": "",
            total: _vm.total,
            "per-page": _vm.perPage,
            "current-page": _vm.currentPage,
            "aria-next-label": "Nächste Seite",
            "aria-previous-label": "Vorherige Seite",
            "aria-page-label": "Seite",
            "aria-current-label": "Aktuelle Seite",
            "custom-row-key": "id",
            loading: _vm.isLoading
          },
          on: { "page-change": _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { field: "title", label: "Titel", width: "475" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.title) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: { field: "status", label: "Status", width: "250" }
                    },
                    [
                      _c("status-tag", {
                        key: props.row.id,
                        attrs: { status: props.row.status }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      staticClass: "text-align-right",
                      attrs: { field: "id", label: "Aktion" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.openEditorModal(props.row.id)
                            }
                          }
                        },
                        [_c("b-icon", { attrs: { icon: "edit" } })],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "table--empty" }, [
              _c("p", [_vm._v("Keine Verfahren gefunden.")])
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$root.$data.loadingIndicator,
              expression: "!this.$root.$data.loadingIndicator"
            }
          ],
          attrs: {
            active: _vm.isMethodEditorModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isMethodEditorModalActive = $event
            }
          }
        },
        [
          _c("method-editor", {
            attrs: {
              "contract-id": _vm.contractId,
              "method-id": _vm.selectedMethod,
              canceled: _vm.canceled
            },
            on: { reloadMethodList: _vm.reloadData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }