<template>
    <section>
        <h2>Freigaben</h2>
        <b-table
            :data="tableData"
            custom-row-key="id"
            :paginated="total > 5"
            per-page="5"
            :current-page.sync="currentPage"
            :total="total"
            @page-change="onPageChange"
            backend-pagination
            :loading="isLoading"
        >
            <template slot-scope="props">
                <b-table-column field="title" label="Titel">
                    {{ props.row.title }}
                </b-table-column>
                <b-table-column field="title" label="Parteien">
                    <span v-html="props.row.parties"></span>
                </b-table-column>
                <b-table-column field="status" label="Status">
                    <status-tag :status="props.row.status"/>
                </b-table-column>
                <b-table-column field="contract_id" label="" class="has-text-right">
                    <button class="button" @click="openEditorModal(props.row.id, props.row.contract_id)"><b-icon icon="edit"/></button>
                </b-table-column>
            </template>
            <template slot="empty">
                <div class="table--empty">
                    <p>Keine Verfahren gefunden.</p>
                </div>
            </template>
        </b-table>
        <b-modal
            :active.sync="isMethodEditorModalActive"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
            v-show="!this.$root.$data.loadingIndicator"
        >
            <method-editor :contract-id="contractId" :method-id="selectedMethod" @reloadMethodList="loadData"/>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios';
    import { Table } from 'buefy';
    import StatusTag from './StatusTag';
    import MethodEditor from './MethodEditor';

    export default {
        name: 'OpenMethods',
        components: {
            Table,
            StatusTag,
            MethodEditor
        },
        data: function() {
            return {
                tableData: [],
                total: 0,
                currentPage: 1,
                isLoading: true,
                selectedMethod: null,
                isMethodEditorModalActive: false,
                contractId: null,
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            /**
             * Load data from backend.
             *
             * @param resetPage
             */
            loadData(resetPage = true)
            {
                this.isLoading = true;
                if(resetPage) {
                    this.currentPage = 1;
                }
                axios.get('/method/open', {params: {perPage: 5, page: this.currentPage}})
                    .then((response) => {
                        if(!response.data.error) {
                            this.tableData = [];
                            this.total = response.data.total;
                            response.data.data.forEach((method) => {
                                this.tableData.push({
                                    'title': method.title,
                                    'parties': method.contract.customer.name + ' <i class="fas fa-long-arrow-alt-right"></i> ' + method.contract.contractor.name,
                                    'status': method.status,
                                    'contract_id': method.contract.id,
                                    'id': method.id
                                });
                            });
                        }
                    }).finally(() => {
                        this.isLoading = false;
                })
            },
            /**
             * Page change handler.
             *
             * @param page
             */
            onPageChange(page) {
                this.currentPage = page;
                this.loadData(false);
            },
            /**
             * Open the editor modal for the selected method.
             *
             * @param id
             * @param contractId
             */
            openEditorModal(id, contractId) {
                this.contractId = contractId;
                this.selectedMethod = id;
                this.isMethodEditorModalActive = true;
            }
        }
    }
</script>
