var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$root.loaded
    ? _c(
        "div",
        [
          _c("router-view"),
          _vm._v(" "),
          _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: this.$root.$data.loadingIndicator
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }