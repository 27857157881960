var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "is-grid gap-2" },
    [
      _vm.loaded
        ? [
            _vm.location.id
              ? _c("h2", [_vm._v("Standort bearbeiten")])
              : _c("h2", [_vm._v("Standort anlegen")]),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Name" } },
              [
                _c("b-input", {
                  ref: "locationName",
                  attrs: { required: "", "use-html5-validation": "" },
                  model: {
                    value: _vm.location.name,
                    callback: function($$v) {
                      _vm.$set(_vm.location, "name", $$v)
                    },
                    expression: "location.name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Adresse" } },
              [
                _c("b-input", {
                  ref: "locationAddress",
                  attrs: { required: "", "use-html5-validation": "" },
                  model: {
                    value: _vm.location.address,
                    callback: function($$v) {
                      _vm.$set(_vm.location, "address", $$v)
                    },
                    expression: "location.address"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { type: "is-primary" },
                on: { click: _vm.onSaveButtonClick }
              },
              [_vm._v("Speichern")]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }