<template>
    <div class="methodLocationList">
        <div class="methodLocationList__head">
            <b-field label="Art der Daten"/>
            <b-field label="Kategorie"/>
        </div>
        <div class="methodLocationList__row"  v-for="(methodLocation, index) in myMethodLocations" :key="`method-location-${methodLocation.id}`"  >
            <method-location-element :method-location.sync="myMethodLocations[index]" v-model="myMethodLocations[index]" :can-edit="editMode.includes(index)" :ref="'mu-' + index" />
            <div class="methodLocationList__action">
                <span class="cursor-pointer">
                    <i class="fas fa-edit col3" @click="setEditMode(index)" v-show="!editMode.includes(index) && !cannotEdit"></i>
                </span>
                <span class="cursor-pointer">
                    <i class="fas fa-trash-alt col4" @click="deleteMethodLocation(index)" v-if="!cannotEdit && myMethodLocations.length > 1"></i>
                </span>
            </div>
        </div>
        <button class="button has-margin-top-md" @click="addMethodLocation" :disabled="cannotEdit" v-if="!cannotEdit"><b-icon icon="folder-plus"/></button>
    </div>
</template>

<script>
    import MethodLocationElement from "./MethodLocationElement";
    export default {
        name: 'MethodLocations',
        components: {MethodLocationElement},
        props: ['methodLocations', 'cannotEdit'],
        data() {
            return {
                myMethodLocations: _.cloneDeep(this.methodLocations),
                editMode: []
            }
        },
        mounted() {
            if(this.myMethodLocations.length === 1 && !this.myMethodLocations[0].hasOwnProperty('id')) {
                this.setEditMode(0);
            }
        },
        watch: {
            myMethodLocations: {
                handler: function(newValue, oldValue) {
                    this.$emit('input', newValue);
                },
                deep: true,
            }
        },
        methods: {
            /**
             * Add a new method location.
             */
            addMethodLocation() {
                this.myMethodLocations.push({location: '', type: ''});
                this.editMode.push(this.myMethodLocations.length - 1);
            },
            /**
             * Delete the given method location.
             *
             * @param index
             */
            deleteMethodLocation(index) {
                console.log(this.myMethodLocations, index);
                this.$delete(this.myMethodLocations, index);
                this.$delete(this.editMode, index);
            },
            /**
             * Enable edit mode for the selected method location.
             *
             * @param index
             */
            setEditMode(index)
            {
                this.$refs['mu-'+index][0].editMode = true;
                this.editMode.push(index);
            },
            /**
             * Check if the given button hidden.
             *
             * @param index
             * @returns {*}
             */
            isHidden(index)
            {
                return this.hiddenEditButtons.includes(index);
            },
            validate()
            {
                let index = 0;
                for(index; index < this.myMethodLocations.length; index++) {
                    if(!this.$refs['mu-' + index][0].validate()) {
                        return false;
                    }
                }
                return true;
            }
        }
    }
</script>
