var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "methodUserList__data" },
    [
      _c(
        "b-field",
        [
          _vm.editMode
            ? _c("b-input", {
                ref: "username",
                attrs: { placeholder: "Name", required: "" },
                model: {
                  value: _vm.myMethodUser.username,
                  callback: function($$v) {
                    _vm.$set(_vm.myMethodUser, "username", $$v)
                  },
                  expression: "myMethodUser.username"
                }
              })
            : _c("label", [_vm._v(_vm._s(_vm.myMethodUser.username))])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        [
          _vm.editMode
            ? _c("b-input", {
                ref: "email",
                attrs: { type: "email", placeholder: "Email", required: "" },
                model: {
                  value: _vm.myMethodUser.email,
                  callback: function($$v) {
                    _vm.$set(_vm.myMethodUser, "email", $$v)
                  },
                  expression: "myMethodUser.email"
                }
              })
            : _c("label", [_vm._v(_vm._s(_vm.myMethodUser.email))])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        [
          _vm.editMode
            ? _c("b-input", {
                ref: "role",
                attrs: { placeholder: "Rolle", required: "" },
                model: {
                  value: _vm.myMethodUser.company_role,
                  callback: function($$v) {
                    _vm.$set(_vm.myMethodUser, "company_role", $$v)
                  },
                  expression: "myMethodUser.company_role"
                }
              })
            : _c("label", [_vm._v(_vm._s(_vm.myMethodUser.company_role))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }