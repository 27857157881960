<template>
    <section class="userList">
        <div class="is-flex items-center justify-between">
            <h2>Benutzerverwaltung</h2>
            <div class="userListControl">
                <b-select placeholder="Filter" icon="user-tag" v-model="roleFilter" @input="loadData">
                    <option value="" selected>Alle</option>
                    <option value="admin">Admin</option>
                    <option value="manager">Manager</option>
                    <option value="user">User</option>
                </b-select>
                <b-select placeholder="Filter" icon="building" v-model="locationFilter" @input="loadData">
                    <option value="" selected>Alle</option>
                    <option v-for="location in locations" :value="location.id">{{location.name}}</option>
                </b-select>
                <b-select placeholder="Sortierung" icon="sort-amount-down" v-model="orderBy" @input="loadData">
                    <option value="name_asc" selected>Name aufsteigend</option>
                    <option value="name_desc">Name absteigend</option>
                    <option value="location_asc">Ort aufsteigend</option>
                    <option value="location_desc">Ort absteigend</option>
                    <option value="role_asc">Rolle aufsteigend</option>
                    <option value="role_desc">Rolle absteigend</option>
                </b-select>
            </div>
            <b-button type="is-primary" @click="openEditorModal(null)">Neuen Benutzer anlegen</b-button>
        </div>

        <b-table
            :data="users"
            :paginated="total > perPage"
            backend-pagination
            backend-sorting
            backend-filtering
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            :current-page="currentPage"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            custom-row-key="id"
            :loading="isLoading"
        >
            <template slot-scope="props">
                <b-table-column field="name" label="Name">
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column field="email" label="Email">
                    {{ props.row.email }}
                </b-table-column>
                <template v-if="!isSuperadmin">
                    <b-table-column field="location" label="Standort">
                        <template v-if="props.row.location">
                            {{ props.row.location.name }}
                        </template>
                    </b-table-column>
                    <b-table-column field="role" label="Rolle">
                        {{ props.row.roles[0].name }}
                    </b-table-column>
                </template>
                <b-table-column field="id" label="Aktionen" class="has-text-right">
                    <b-button @click="openEditorModal(props.row.id)" icon-right="edit" />
                    <b-button type="is-danger" @click="openConfirmDeleteDialog(props.row.id)" icon-right="trash" v-if="userId !== props.row.id"/>
                </b-table-column>
            </template>
            <template slot="empty">
                <div class="table--empty">
                    <p>Keine Benutzer gefunden.</p>
                </div>
            </template>
        </b-table>
        <b-modal
        :active.sync="isUserEditorModalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        v-show="!this.$root.$data.loadingIndicator"
        >
            <user-editor :user-id="selectedUser" @reloadUserList="loadData"/>
        </b-modal>
    </section>
</template>

<script>
    import UserEditor from './UserEditor';
    export default {
        name: 'UserList',
        components: {UserEditor},
        data() {
            return {
                users: [],
                locations: [],
                currentPage: 1,
                total: 0,
                perPage: 10,
                isLoading: true,
                selectedUser: null,
                isUserEditorModalActive: false,
                roleFilter: '',
                locationFilter: '',
                orderBy: 'name_asc'
            }
        },
        created() {
            axios.all([this.getLoadDataRequest(), this.getLocationsRequest()]).then(axios.spread((users, locations) => {
                this.$set(this, 'users', users.data.data);
                this.$set(this, 'locations', locations.data);
            })).finally(() => {
                this.isLoading = false;
            });
        },
        computed: {
            userId()
            {
                return this.$root.$data.userId;
            },
            isSuperadmin()
            {
                return this.$root.$data.roles.includes('superadmin');
            }
        },
        methods: {
            /**
             * Load data from backend.
             *
             * @param resetPage
             */
            loadData(resetPage = true)
            {
                this.isLoading = true;
                if(resetPage) {
                    this.currentPage = 1;
                }
                let url = '/user/get?page=' + this.currentPage;
                axios.get(url, {
                    params: {
                        orderBy: this.orderBy,
                        locationFilter: this.locationFilter,
                        roleFilter: this.roleFilter,
                    }
                }).then((response) => {
                    this.users = [];
                    response.data.data.forEach((user) => {
                        this.users.push(user);
                    });
                }).finally(() => {
                    this.isLoading = false;
                })
            },
            /**
             * Get request to load data.
             *
             * @returns {*}
             */
            getLoadDataRequest()
            {
                return axios.get('/user/get?page=' + this.currentPage, {
                    params: {
                        orderBy: this.orderBy,
                        locationFilter: this.locationFilter,
                        roleFilter: this.roleFilter
                    }
                });
            },
            /**
             * Get request to load locations.
             *
             * @returns {*}
             */
            getLocationsRequest()
            {
                return axios.get('/location/all');
            },
            /**
             * Page change handler.
             *
             * @param page
             */
            onPageChange(page)
            {
                this.currentPage = page;
                this.loadData(false);
            },
            /**
             * Open editor modal for the given user ID.
             *
             * @param id
             */
            openEditorModal(id)
            {
                this.selectedUser = id;
                this.isUserEditorModalActive = true;
            },
            /**
             * Delete handler.
             *
             * @param id
             */
            openConfirmDeleteDialog(id)
            {
                this.$buefy.dialog.confirm({
                    title: 'Benutzer löschen',
                    message: 'Sind Sie sicher, dass Sie den Benutzer löschen wollen?',
                    confirmText: 'Löschen',
                    cancelText: 'Abbrechen',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => axios.delete('/user/delete/' + id).then((response) => {
                        if(!response.data.error) {
                            this.$buefy.notification.open({
                                message: 'Der Benutzer wurde erfolgreich gelöscht.',
                                type: 'is-success',
                                position: 'is-top-right',
                                duration: 3500,
                            })
                        }
                    }).finally(() => {
                        this.loadData();
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .userListControl {
        display: grid;
        grid-gap: 1rem;

        & > * {
            grid-row: 1;
        }
    }
</style>
