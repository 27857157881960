<template>
    <section>
        <template v-if="loaded">
            <h2 class="col12">Kunden bearbeiten</h2>
            <b-field label="Name" class="col12">
                <b-input v-model="client.name"/>
            </b-field>
            <b-field label="Ansprechpartner" class="col12">
                <b-select v-model="client.invoice_user_id">
                    <option v-for="(user, index) in adminUsers" :value="user.id">{{user.name}}</option>
                </b-select>
            </b-field>
            <b-field label="Datenschutzbeauftragter" class="col12">
                <b-select v-model="client.active_admin">
                    <option v-for="(user, index) in adminUsers" :value="user.id">{{user.name}}</option>
                </b-select>
            </b-field>
            <b-button type="is-primary" @click="saveClient">Speichern</b-button>
        </template>
    </section>
</template>

<script>
    import {EventBus} from '../../eventbus';

    export default {
        name: 'ClientEditor',
        props: ['clientId'],
        data() {
            return {
                loaded: false,
                client: null,
                adminUsers: [],
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            /**
             * Get request to load the client.
             *
             * @returns {*}
             */
            getGetClientRequest()
            {
                return axios.get('/admin/get/client/' + this.clientId);
            },
            /**
             * Get request to load all admin users.
             *
             * @returns {*}
             */
            getGetAdminUsersRequest()
            {
                return axios.get('/admin/get/adminsByClientId/' + this.clientId);
            },
            /**
             * Load data.
             */
            loadData()
            {
                EventBus.$emit('setLoadingIndicator');
                axios.all([this.getGetClientRequest(), this.getGetAdminUsersRequest()]).then(axios.spread((client, adminUsers) => {
                    if(!(client.data && adminUsers.data) || client.data.error || adminUsers.data.error) {
                        return;
                    }
                    this.client = client.data;
                    this.$set(this, 'adminUsers', adminUsers.data.data);
                })).finally(() => {
                    this.loaded = true;
                    EventBus.$emit('unsetLoadingIndicator');
                });
            },
            /**
             * Save the client.
             */
            saveClient()
            {
                EventBus.$emit('setLoadingIndicator');
                axios.patch('/admin/update/client/' + this.client.id, {
                    name: this.client.name,
                    invoice_user_id: this.client.invoice_user_id,
                    active_admin: this.client.active_admin,
                }).then((response) => {
                    if(response.data.error) {
                        return;
                    }
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Der Kunde wurde bearbeitet.'
                    });
                    this.$emit('loadData');
                    EventBus.$emit('unsetLoadingIndicator');
                    this.$parent.close();

                }).finally(() => {
                    EventBus.$emit('unsetLoadingIndicator');
                });
            }
        }
    }
</script>

<style scoped>

</style>
