var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subList" },
    [
      _vm._l(_vm.mySubsInMethod, function(subInMethod, index) {
        return _c("div", { staticClass: "subList__inMethodRow" }, [
          _c("p", [_vm._v(_vm._s(subInMethod.name))]),
          _vm._v(" "),
          !_vm.editDisabled
            ? _c("i", {
                staticClass: "fas fa-trash-alt",
                on: {
                  click: function($event) {
                    return _vm.removeFromMethod(index)
                  }
                }
              })
            : _vm._e()
        ])
      }),
      _vm._v(" "),
      !_vm.addDisabled
        ? _c(
            "div",
            { staticClass: "subList__subSelect" },
            [
              _c(
                "b-field",
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        placeholder: "Hinzufügen...",
                        disabled: _vm.addDisabled,
                        icon: "building"
                      },
                      on: { input: _vm.addToMethod },
                      model: {
                        value: _vm.selectedSubToAdd,
                        callback: function($$v) {
                          _vm.selectedSubToAdd = $$v
                        },
                        expression: "selectedSubToAdd"
                      }
                    },
                    [
                      _c(
                        "option",
                        {
                          key: -1,
                          attrs: { selected: "" },
                          domProps: { value: -1 }
                        },
                        [_vm._v("Hinzufügen...")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.mySubsInContract, function(
                        subInContract,
                        index
                      ) {
                        return _c(
                          "option",
                          { key: subInContract.id, domProps: { value: index } },
                          [_vm._v(_vm._s(subInContract.name))]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }