var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("Organisationsverwaltung")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Datenschutzbeauftragter der Organisation" } },
        [
          !_vm.isLoading
            ? _c(
                "b-select",
                {
                  on: { input: _vm.onSaveButtonClick },
                  model: {
                    value: _vm.currentAdmin,
                    callback: function($$v) {
                      _vm.currentAdmin = $$v
                    },
                    expression: "currentAdmin"
                  }
                },
                _vm._l(_vm.users, function(user) {
                  return _c(
                    "option",
                    { key: user.id, domProps: { value: user.id } },
                    [_vm._v(_vm._s(user.name))]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n        Der hier bestimmte Datenschutzbeauftragte bekommt alle Benachrichtigungen dieser Plattform.\n        "
      ),
      _c("br"),
      _vm._v(
        "\n        Es können nur Nutzer mit der Rolle 'Admin' als Datenschutzbeauftragte besetzt werden.\n    "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }