var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "is-grid gap-2" },
    [
      _vm.contract
        ? [
            _c("div", [
              _c("h3", { staticClass: "has-margin-bottom-xs" }, [
                _vm._v("Auftraggeber")
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.contract.customer.name))])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h3", { staticClass: "has-margin-bottom-xs" }, [
                _vm._v("Auftragnehmer")
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.contract.contractor.name))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("h3", { staticClass: "has-margin-bottom-xs" }, [
                  _vm._v("Vertragsbeginn")
                ]),
                _vm._v(" "),
                _c("b-datepicker", {
                  staticClass: "contractControl__datepicker",
                  attrs: {
                    disabled: _vm.isContractDisabled,
                    "date-formatter": _vm.formatDate
                  },
                  on: { input: _vm.updateContract },
                  model: {
                    value: _vm.startDate,
                    callback: function($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("h3", { staticClass: "has-margin-bottom-xs" }, [
                _vm._v("Auswählbare Subs")
              ]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.subsInLocation, function(sub, index) {
                  return _c(
                    "li",
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: { disabled: _vm.isSublocationDisabled(index) },
                          on: { input: _vm.updateContract },
                          model: {
                            value: _vm.subsInContract[sub.id],
                            callback: function($$v) {
                              _vm.$set(_vm.subsInContract, sub.id, $$v)
                            },
                            expression: "subsInContract[sub.id]"
                          }
                        },
                        [_vm._v(_vm._s(sub.name))]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h3", { staticClass: "has-margin-bottom-xs" }, [
                _vm._v("Anhänge")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "contractControl__attachments" }, [
                _c("p", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/contract/generatepdf/" + this.contractId + "?type=a"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-paperclip" }),
                      _vm._v(" Anhang A drucken")
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/contract/generatepdf/" + this.contractId + "?type=b"
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-paperclip" }),
                      _vm._v(" Anhang B drucken")
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "contractControl__buttons" },
                [
                  !_vm.isContractDisabled
                    ? _c(
                        "b-button",
                        {
                          attrs: { type: "is-danger", outlined: "" },
                          on: { click: _vm.openCancelContractDialog }
                        },
                        [_vm._v("Vertrag beenden")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }