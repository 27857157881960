import ManagerUsers from "./views/ManagerUsers";

require('./bootstrap');
import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy';

Vue.use(VueRouter);
Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultDayNames: ['Mo','Di','Mi','Do','Fr','Sa','So'],
    defaultMonthNames:['Januar','Febuar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
});

window.axios.interceptors.response.use((response) => {
    if(response.config.url === '/user/current') {
        return response;
    }
    if(response.data.error && response.data.status === 'failed') {
        app.$buefy.notification.open({
            type: 'is-danger',
            message: response.data.error,
            position: 'is-top-right',
            duration: 3500
        });
    }
    return response;
}, (error) => {
    app.$buefy.notification.open({
        type: 'is-danger',
        message: error.message,
        position: 'is-top-right',
        duration: 3500
    });
    return Promise.reject(error);
});

import App from './views/App';
import Dashboard from './views/Dashboard';
import Contract from './views/Contract';
import ManageLocations from "./views/ManageLocations";
import ManageOrganization from "./views/ManageOrganization";
import {EventBus} from './eventbus.js';
import ManageProfile from "./views/ManageProfile";
import AdminDashboard from "./views/AdminDashboard";

Vue.mixin({
    methods: {
        isAdmin() {
            return this.hasRole() && this.$root.roles.includes('admin');
        },
        isManager() {
            return this.hasRole() && this.$root.roles.includes('manager');
        },
        isUser() {
            return this.hasRole() && this.$root.roles.includes('user');
        },
        isSuperAdmin() {
            return this.hasRole() && this.$root.roles.includes('superadmin');
        },
        hasRole() {
            return this.$root.roles.length;
        },
    }
});

const appName = 'Advocat';

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                title: `Dashboard - ${appName}`
            }
        },
        {
            path: '/contract/:contractId',
            name: 'contract',
            component: Contract,
            props: true,
            meta: {
                title: `Vertragseditor - ${appName}`
            }
        },
        {
            path: '/manage/users',
            name: 'manageUsers',
            component: ManagerUsers,
            meta: {
                title: `Benutzerverwaltung - ${appName}`
            }
        },
        {
            path: '/manage/locations',
            name: 'manageLocations',
            component: ManageLocations,
            meta: {
                title: `Standortverwaltung - ${appName}`
            }
        },
        {
            path: '/manage/organization',
            name: 'manageOrganization',
            component: ManageOrganization,
            meta: {
                title: `Organisationsverwaltung - ${appName}`
            }
        },
        {
            path: '/manage/profile',
            name: 'manageProfile',
            component: ManageProfile,
            meta: {
                title: `Profilverwaltung - ${appName}`
            }
        },
        {
            path: '/admin/dashboard',
            name: 'adminDashboard',
            component: AdminDashboard,
            meta: {
                title: `Admindashboard - ${appName}`
            }
        },
        {
            // Workaround to prevent the catch-all to from redirecting password reset requests
            path: '/password/*'
        },
        {
            path: '*',
            name: 'catch-all',
            redirect: '/'
        },
    ],
});
import debounce from 'lodash/debounce';

const app = new Vue({
    el: '#app',
    components: { App },
    router,
    data() {
        return {
            userId: null,
            name: null,
            username: null,
            roles: [],
            loaded: false,
            client: null,
            loadingIndicator: false,
            searchResults: [],
            isSearching: false,
        }
    },
    created()
    {
        EventBus.$on('setLoadingIndicator', () => {
            this.loadingIndicator = true
        });
        EventBus.$on('unsetLoadingIndicator', () => {
            this.loadingIndicator = false
        });
    },
    mounted() {
        if(this.$route.meta.title) {
            document.title = this.$route.meta.title;
        }
        axios.get('/user/current').then((response) => {
            if(response.data) {
                if(!response.data.username || !response.data.roles) {
                    this.$buefy.notifications.open({
                        type: 'is-danger',
                        message: 'Benutzerdaten konnten nicht abgerufen werden.'
                    });
                    this.$router.replace('/login');
                    return false;
                }
                this.userId = response.data.userId;
                this.username = response.data.username;
                this.name = response.data.name;
                response.data.roles.forEach((role) => {
                    this.roles.push(role.name);
                });
                if(this.roles.includes('superadmin') && this.$router.currentRoute.path !== '/admin/dashboard') {
                    this.$router.replace('/admin/dashboard');
                }
                // the response contains the client only when the current user is an admin
                // so no need to check for it here again
                if(response.data.client) {
                    this.client = response.data.client;
                    if(!this.client.active_admin) {
                        this.$buefy.dialog.confirm({
                            message: 'Für Ihre Organisation wurde kein zuständiger Datenschutzbeauftragter bestimmt.' +
                                '<br>' +
                                'Bitte bestimmen Sie im Bereich "Organisationsverwaltung" einen Beauftragen, der die Benachrichtigungen dieser Plattform erhalten soll.',
                            title: 'Datenschutzbeauftragen bestimmen',
                            cancelText: 'ignore',
                            confirmText: 'Organisationsverwaltung aufrufen',
                            onConfirm: () => {
                                this.$router.push({name: 'manageOrganization'});
                            }
                        })
                    }
                }
            }
        }).finally(() => {
            this.loaded = true;
        });
    },
    methods: {
        getSearchData: debounce(function(term) {
            if(!term.length || term.length < 3) {
                this.searchResults = [];
                return;
            }
            this.isSearching = true;
            axios.get('/search', {params: {term: term}}).then((response) => {
                this.$set(this, 'searchResults', response.data);
            }, 500).finally(() => {
                this.isSearching = false;
            })
        }),
        openContract(contractId)
        {
            this.$router.push({name: 'contract', params: {contractId: contractId}});
        }
    }
});

app.$router.beforeEach((to, from, next) => {
    if((!app.username || !app.roles) && to.path !== '/login') {
        next('/login');
        return;
    }

    if(to.name === 'dashboard' && app.roles.includes('superadmin')) {
        next('/admin/dashboard');
        return;
    }

    if(to.name === 'manageLocations') {
        if(!app.isAdmin()) {
            app.$buefy.notification.open({
                type: 'is-danger',
                message: 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen.'
            });
            next('/');
            return;
        }
    }

    if(to.name === 'manageUsers') {
        if(!app.isAdmin() && !app.roles.includes('superadmin')) {
            app.$buefy.notification.open({
                type: 'is-danger',
                message: 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen.'
            });
            next('/');
            return;
        }
    }

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});
