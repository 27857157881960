<template>
    <section>
        <h2>Organisationsverwaltung</h2>
        <p>
            Der hier bestimmte Datenschutzbeauftragte bekommt alle Benachrichtigungen dieser Plattform.
            <br>
            Es können nur Nutzer mit der Rolle 'Admin' als Datenschutzbeauftragte besetzt werden.
        </p>
        <b-field label="Datenschutzbeauftragter der Organisation">
            <b-select v-if="!isLoading" v-model="currentAdmin" @input="onSaveButtonClick">
                <option
                    v-for="user in users"
                    :value="user.id"
                    :key="user.id"
                >{{user.name}}</option>
            </b-select>
        </b-field>
    </section>
</template>

<script>
    import {EventBus} from "../eventbus";

    export default {
        name: 'ManageOrganization',
        data() {
            return {
                users: [],
                organization: null,
                currentAdmin: null,
                isLoading: true,
            }
        },
        created() {
            axios.all([this.getGetUsersRequest(), this.getGetCurrentOrganizationRequest()]).then(axios.spread((users, organization) => {
                if(users.data.data.error) {
                    return;
                }
                this.$set(this, 'organization', organization.data);
                this.$set(this, 'users', users.data.data);
                this.$set(this, 'currentAdmin', organization.data.active_admin);
                console.log(this.$root.$data);
            })).finally(() => {
                this.isLoading = false;
            });
        },
        methods: {
            /**
             * Get request to get admin users.
             *
             * @returns {*}
             */
            getGetUsersRequest()
            {
                return axios.get('/user/getAdmins')
            },
            /**
             * Get request to get the current organization.
             *
             * @returns {*}
             */
            getGetCurrentOrganizationRequest()
            {
                return axios.get('/organization/current');
            },
            /**
             * Save button click handler.
             */
            onSaveButtonClick()
            {
                EventBus.$emit('setLoadingIndicator');
                axios.post('/organization/update', {
                    current_admin: this.currentAdmin
                }).then((response) => {
                    if(response.data.error) {
                        return;
                    }
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Der Benutzer wurde erfolgreich als zuständiger Datenschutzbeauftrager gesetzt.'
                    });
                }).finally(() => {
                    EventBus.$emit('unsetLoadingIndicator');
                })
            }
        }
    }
</script>
