<template>
    <section class="method-editor">
        <template v-if="loaded">
            <h2 class="full-row">Verfahren: {{method.title}}</h2>
            <b-field class="grid-col-1" label="Auftraggeber">
                <p>{{method.customer.name}}</p>
            </b-field>
            <span class="grid-col-2 has-padding-none arrow" style="align-self: flex-end;"><i class="fas fa-long-arrow-alt-right"></i></span>
            <b-field class="grid-col-3" label="Auftragnehmer">
                <p>{{method.contractor.name}}</p>
            </b-field>

            <b-field class="full-row" label="Titel">
                <b-input v-model="method.title" required :readonly="cannotEdit" ref="methodTitleInput"/>
            </b-field>

            <b-field class="full-row" label="Beschreibung">
                <b-input type="textarea" v-model="method.description" required :readonly="cannotEdit" ref="methodDescriptionInput"/>
            </b-field>

            <b-field class="full-row is-flex is-flex-col" label="Gültigkeit" message="(Ablaufdatum optional)">
                <div class="sub-grid">
                    <b-datepicker v-model="method.date_valid_from" required :disabled="cannotEdit" expanded ref="methodStartDateInput"/>
                    <p class="align-self-center">bis</p>
                    <div class="is-relative">
                        <b-datepicker v-model="method.date_valid_to" :disabled="cannotEdit" expanded ref="methodEndDateInput"/>
                        <span class="date-remove">
                            <i class="fas fa-times-circle" @click="method.date_valid_to = null" title="Enddatum löschen"
                               v-if="!cannotEdit && method.date_valid_to"></i>
                        </span>
                    </div>
                </div>
            </b-field>

            <b-field class="full-row" label="Ort und Art der Datenspeicherung">
                <method-locations v-model="method.method_locations" :method-locations="method.method_locations"
                              :cannotEdit="cannotEdit" ref="methodLocationInput"/>
                <!--<b-input type="textarea" v-model="method.text" required :readonly="cannotEdit" ref="methodTextInput"/>-->
            </b-field>

            <b-field class="full-row" label="Sicherheitsmaßnahmen">
                <b-input type="textarea" v-model="method.tom" required :readonly="cannotEdit" ref="methodTOMInput"/>
            </b-field>

            <b-field class="full-row" label="Personen und Rollen">
                <method-users v-model="method.method_users" :method-users="method.method_users"
                              :cannotEdit="cannotEdit" ref="methodUserInput"/>
            </b-field>

            <b-field class="full-row" label="Subs">
                <sub-list
                    v-model="method.subs_in_method"
                    :subs-in-method="method.subs_in_method"
                    :subs-in-contract="method.subs_in_contract"
                    @update:subsInContract="method.subs_in_contract = $event"
                    :disabled="cannotEdit"
                />
            </b-field>

            <div class="full-row is-flex justify-between">
                <template v-if="!cannotEdit">
                    <b-tooltip class="is-primary" multilined
                               label='Durch das Speichern von Änderungen wird das Verfahren automatisch auf den Status "offen" gesetzt und muss vom zuständigen Beauftragen freigeschaltet werden.'>
                        <b-button class="has-margin-right-sm" type="is-primary" @click="saveOrCreateMethod" v-if="!cannotEdit">
                            Speichern
                        </b-button>
                    </b-tooltip>
                </template>
                <template v-if="(isAdmin() || isSuperAdmin()) && !canceled">
                    <div>
                        <b-button class="has-margin-right-sm is-outlined" type="is-danger" @click="deleteMethod" v-if="isAdmin || isSuperAdmin">Verfahren
                            löschen
                        </b-button>
                        <template v-if="method.id && (isAdmin || isSuperAdmin)">
                            <b-button class="has-margin-right-sm" type="is-outlined" v-if="method.status === 'open'" @click="approveMethod">
                                Verfahren freigeben
                            </b-button>
                            <b-button type="is-outlined" v-if="method.status === 'active'" @click="disapproveMethod">Verfahren
                                zurückziehen
                            </b-button>
                        </template>
                    </div>

                </template>
            </div>
        </template>
    </section>
</template>

<script>
    import {Input} from 'buefy';
    import MethodUsers from "./MethodUsers";
    import SubList from './SubList';
    import {EventBus} from "../eventbus";
    import MethodLocations from "./MethodLocations";

    export default {
        name: 'MethodEditor',
        props: ['contractId', 'methodId', 'canceled'],
        components: {MethodLocations, Input, MethodUsers, SubList},
        data() {
            return {
                method: {
                    id: null,
                    contract_id: null,
                    title: 'Neues Verfahren',
                    customer: null,
                    contractor: null,
                    description: null,
                    date_valid_from: new Date(),
                    date_valid_to: null,
                    text: null,
                    tom: null,
                    method_locations: [],
                    method_users: [],
                    subs_in_contract: [],
                    subs_in_method: [],
                },
                loaded: false,
            }
        },
        created() {
            if (this.contractId && !this.methodId) {
                this.loadNewMethodScaffolding();
            } else {
                this.loadMethod();
            }
        },
        computed: {
            cannotEdit() {
                return this.canceled || this.isUser();
            }
        },
        methods: {
            /**
             * Get scaffolding for a new method.
             */
            loadNewMethodScaffolding() {
                EventBus.$emit('setLoadingIndicator');
                axios.get('/method/get/newByContractId/' + this.contractId).then((response) => {
                    this.method.customer = response.data.customer;
                    this.method.contractor = response.data.contractor;
                    this.method.subs_in_contract = Array.isArray(response.data.subs) ? response.data.subs : [response.data.subs];
                    this.method.contract_id = this.contractId;
                    this.method.method_users.push({
                        username: '',
                        email: '',
                        company_role: '',
                    });
                    this.method.method_locations.push({
                        location: '',
                        type: ''
                    })
                }).finally(() => {
                    this.loaded = true;
                    EventBus.$emit('unsetLoadingIndicator');
                })
            },
            /**
             * Get method.
             */
            loadMethod() {
                EventBus.$emit('setLoadingIndicator');
                axios.all([this.requestLoadMethod(), this.requestLoadSubsInContract()]).then(axios.spread((method, subs) => {
                    this.method = method.data;
                    this.method.subs_in_method = Array.isArray(method.data.subs) ? method.data.subs : [method.data.subs];
                    let subsInMethod = this.method.subs_in_method.reduce((accumulator, arrayElement, index) => {
                        accumulator.push(arrayElement.id);
                        return accumulator;
                    }, []);
                    let subsInContract = Array.isArray(subs.data) ? subs.data : [subs.data];
                    this.method.subs_in_contract = subsInContract.reduce((accumulator, arrayElement, index) => {
                        if (!subsInMethod.includes(arrayElement.id)) {
                            accumulator.push(arrayElement);
                        }
                        return accumulator;
                    }, []);
                    this.method.date_valid_from = new Date(Date.parse(method.data.date_valid_from));
                    this.method.date_valid_to = method.data.date_valid_to ? new Date(Date.parse(method.data.date_valid_to)) : null;
                    this.method.contract_id = this.contractId;
                })).finally(() => {
                    this.loaded = true;
                    EventBus.$emit('unsetLoadingIndicator');
                });
            },
            /**
             * Decide which route to call on save (create new method or update existing).
             */
            saveOrCreateMethod() {
                if (!this.validate()) {
                    return;
                }
                let timeEditMethod = _.cloneDeep(this.method);
                timeEditMethod.date_valid_from = timeEditMethod.date_valid_from.toDateString();
                if (timeEditMethod.date_valid_to) {
                    timeEditMethod.date_valid_to = timeEditMethod.date_valid_to.toDateString();
                }
                // we remove empty user entries as to not trigger a validation error in the backend
                timeEditMethod.method_users.forEach((user, index) => {
                    if (!user.email && !user.username && !user.role) {
                        this.$delete(timeEditMethod.method_users, index);
                    }
                });
                timeEditMethod.method_locations.forEach((location, index) => {
                    if (!location.location && !location.type) {
                        this.$delete(timeEditMethod.method_locations, index);
                    }
                });
                if (!this.method.id) {
                    axios.post('/method/create', {
                        method: timeEditMethod
                    }).then((response) => {
                        if (response.data.error) {
                            this.$buefy.notification.open({
                                type: 'is-danger',
                                message: 'Das Verfahren wurde nicht angelegt.'
                            });
                            return;
                        }
                        this.$emit('reloadMethodList');
                        this.$buefy.notification.open({
                            type: 'is-success',
                            message: 'Das Verfahren wurde erfolgreich angelegt.'
                        });
                        this.$parent.close();
                    });
                } else {
                    axios.patch('/method/edit', {
                        method: timeEditMethod
                    }).then((response) => {
                        if (response.data.error) {
                            this.$buefy.notification.open({
                                type: 'is-danger',
                                message: 'Das Verfahren wurde nicht angelegt.'
                            });
                            return;
                        }
                        this.$emit('reloadMethodList');
                        this.$buefy.notification.open({
                            type: 'is-success',
                            message: 'Das Verfahren wurde erfolgreich bearbeitet.'
                        });
                        this.$parent.close();
                    });
                }
            },
            /**
             * Delete selected method.
             */
            deleteMethod() {
                this.$buefy.dialog.confirm({
                    title: 'Verfahren löschen',
                    message: 'Sind Sie sicher, dass Sie das Verfahren löschen wollen?',
                    confirmText: 'Löschen',
                    cancelText: 'Abbrechen',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => axios.patch('/method/delete/' + this.method.id).then(() => {
                    }).finally(() => {
                        this.$emit('reloadMethodList');
                        this.$buefy.notification.open({
                            type: 'is-success',
                            message: 'Das Verfahren wurde erfolgreich gelöscht.'
                        });
                        this.$parent.close();
                    })
                });
            },
            /**
             * Approve method.
             */
            approveMethod() {
                axios.patch('/method/approve/' + this.method.id).then(() => {
                }).finally(() => {
                    this.$emit('reloadMethodList');
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Das Verfahren wurde erfolgreich freigegeben.'
                    });
                    this.$parent.close();
                });
            },
            /**
             * Disapprove method.
             */
            disapproveMethod() {
                axios.patch('/method/disapprove/' + this.method.id).then(() => {
                }).finally(() => {
                    this.$emit('reloadMethodList');
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Das Verfahren wurde erfolgreich zurückgezogen.'
                    });
                    this.$parent.close();
                });
            },
            /**
             * Get request for new method scaffolding.
             *
             * @returns {*}
             */
            requestLoadNewMethodScaffolding() {
                return axios.get('/method/get/newByContractId/' + this.contractId);
            },
            /**
             * Get request for loading a method.
             *
             * @returns {*}
             */
            requestLoadMethod() {
                return axios.get('/method/get/' + this.methodId);
            },
            /**
             * Get request to load sublocations.
             *
             * @returns {*}
             */
            requestLoadSubsInContract() {
                return axios.get('/location/subsInContract/' + this.contractId);
            },
            /**
             * Validate from inputs.
             *
             * @returns {boolean}
             */
            validate() {
                if(!this.$refs['methodTitleInput'].checkHtml5Validity()
                    || !this.$refs['methodDescriptionInput'].checkHtml5Validity()
                    || !this.$refs['methodStartDateInput'].checkHtml5Validity()
                    || !this.$refs['methodEndDateInput'].checkHtml5Validity()
                    || !this.$refs['methodTOMInput'].checkHtml5Validity()
                    || !this.$refs['methodUserInput'].validate()
                    || !this.$refs['methodLocationInput'].validate()
                ) {
                    this.$buefy.toast.open({
                        message: 'Bitte überprüfen Sie Ihre Eingaben.',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                    return false;
                }

                if(this.method.date_valid_to) {
                    if(this.method.date_valid_to < this.method.date_valid_from) {
                        this.$buefy.toast.open({
                            message: 'Das Enddatum kann nicht vor dem Startdatum liegen.',
                            position: 'is-bottom',
                            type: 'is-danger'
                        });
                        return false;
                    }
                }

                return true;
            }
        }
    }
</script>

<style lang="scss">
    .method-editor {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-gap: 2rem;

        .full-row {
            grid-column: 1 / span 3;
        }

        .grid-col-1 {
            grid-column: 1;
        }

        .grid-col-2 {
            grid-column: 2;
        }

        .grid-col-3 {
            grid-column: 3;
        }

        .sub-grid {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-gap: 2rem;
        }
    }

    .help {
        text-align: right;
    }


    /*.field.col1.has-addons {*/
    /*    display: grid;*/
    /*    grid-template-columns: 1fr 50px 1fr 30px;*/
    /*    justify-items: center;*/
    /*    align-items: center;*/

    /*    label.label {*/
    /*        grid-column: span 4;*/
    /*        justify-self: start;*/
    /*    }*/

    /*    i.fas {*/
    /*        cursor: pointer;*/
    /*    }*/
    /*}*/
</style>
