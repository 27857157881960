var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("Kundenliste")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary addMethodButton",
          on: {
            click: function($event) {
              _vm.isClientCreatorModalActive = true
            }
          }
        },
        [_vm._v("Kunden hinzufügen")]
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          data: _vm.clients,
          paginated: _vm.total > _vm.perPage,
          "backend-pagination": "",
          "backend-sorting": "",
          "backend-filtering": "",
          total: _vm.total,
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
          "aria-next-label": "Next page",
          "aria-previous-label": "Previous page",
          "aria-page-label": "Page",
          "aria-current-label": "Current page",
          "custom-row-key": "id",
          loading: _vm.isLoading
        },
        on: { "page-change": _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { field: "name", label: "Name" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.name) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Aktionen" } },
                  [
                    _c(
                      "b-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openEditorModal(props.row.id)
                          }
                        }
                      },
                      [_c("b-icon", { attrs: { icon: "edit" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { type: "is-danger" },
                        on: {
                          click: function($event) {
                            return _vm.openDeleteDialog(props.row.id)
                          }
                        }
                      },
                      [_c("b-icon", { attrs: { icon: "trash" } })],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$root.$data.loadingIndicator,
              expression: "!this.$root.$data.loadingIndicator"
            }
          ],
          attrs: {
            active: _vm.isClientEditorModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isClientEditorModalActive = $event
            }
          }
        },
        [
          _c("client-editor", {
            attrs: { "client-id": _vm.selectedClient },
            on: { loadData: _vm.loadData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$root.$data.loadingIndicator,
              expression: "!this.$root.$data.loadingIndicator"
            }
          ],
          attrs: {
            active: _vm.isClientCreatorModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isClientCreatorModalActive = $event
            }
          }
        },
        [_c("client-creator", { on: { loadData: _vm.loadData } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }