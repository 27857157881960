<template>
    <section class="is-grid gap-2">
        <template v-if="loaded">
            <h2 v-if="location.id">Standort bearbeiten</h2>
            <h2 v-else>Standort anlegen</h2>
            <b-field label="Name">
                <b-input v-model="location.name" required use-html5-validation ref="locationName"/>
            </b-field>
            <b-field label="Adresse">
                <b-input v-model="location.address" required use-html5-validation ref="locationAddress"/>
            </b-field>
            <b-button type="is-primary" @click="onSaveButtonClick">Speichern</b-button>
        </template>
    </section>
</template>

<script>
    import {EventBus} from "../../eventbus";

    export default {
        name: 'LocationEditor',
        props: {
            type: {
                type: String,
                required: true,
                default: function() {
                    return 'location'
                }
            },
            locationId: {
                type: Number,
            }
        },
        data() {
            return {
                loaded: false,
                location: null,
                createNewLocation: false,
            }
        },
        created() {
            EventBus.$emit('setLoadingIndicator');
            axios.all([this.getLocationRequest()]).then(axios.spread((location) => {
                if(location) {
                    this.$set(this, 'location', location.data);
                } else {
                    this.location = {
                        name: '',
                        address: '',
                        type: this.type,
                    };
                    this.createNewLocation = true;
                }
            })).finally(() => {
                this.loaded = true;
                EventBus.$emit('unsetLoadingIndicator');
            })
        },
        methods: {
            /**
             * Returns the request to get the location.
             *
             * @returns {null|*}
             */
            getLocationRequest()
            {
                if(this.locationId) {
                    return axios.get('/location/get/' + this.locationId);
                }
                return null;
            },
            /**
             * Handles the save button click.
             */
            onSaveButtonClick()
            {
                if(this.createNewLocation) {
                    this.createLocation();
                } else {
                    this.saveLocation();
                }
            },
            /**
             * Fire request to create a new location.
             */
            createLocation()
            {
                if(!this.validate()) {
                    return;
                }
                EventBus.$emit('setLoadingIndicator');
                axios.post('/location/create', {
                    'location': this.location,
                }).then((response) => {
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Der Standort wurde bearbeitet.'
                    });
                    this.$emit('reloadLocationList');
                    EventBus.$emit('unsetLoadingIndicator');
                    this.$parent.close();
                });
            },
            /**
             * Fire request to update an existing location.
             */
            saveLocation()
            {
                if(!this.validate()) {
                    return;
                }
                EventBus.$emit('setLoadingIndicator');
                axios.patch('/location/edit/' + this.location.id, {location: this.location}).then((response) => {
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Der Standort wurde bearbeitet.'
                    });
                    this.$emit('reloadLocationList');
                    EventBus.$emit('unsetLoadingIndicator');
                    this.$parent.close();
                });
            },
            /**
             * Validate the form values.
             *
             * @returns {boolean}
             */
            validate()
            {
                if (
                    !this.$refs['locationName'].checkHtml5Validity()
                    || !this.$refs['locationAddress'].checkHtml5Validity()
                ) {
                    this.$buefy.toast.open({
                        message: 'Bitte überprüfen Sie Ihre Eingaben.',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                    return false;
                }

                return true;
            }
        }
    }
</script>
