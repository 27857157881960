<template>
    <section>
        <h2>Standortverwaltung</h2>
        <location-list type="location"/>
        <location-list type="sub"/>
    </section>
</template>

<script>
    import LocationList from '../components/manage/LocationList';
    export default {
        name: 'ManageLocations',
        components: {LocationList}
    }
</script>

<style scoped>

</style>
