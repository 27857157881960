<template>
    <section>
        <user-list/>
    </section>
</template>

<script>
    import UserList from '../components/manage/UserList';

    export default {
        name: "ManagerUsers",
        components: {UserList}
    }
</script>

<style scoped>

</style>
