<template>
    <div v-if="this.$root.loaded">
        <router-view></router-view>
        <b-loading :is-full-page="true" :active="this.$root.$data.loadingIndicator"/>
    </div>
</template>

<script>
    import {Loading} from 'buefy';

    export default {
        components: {Loading},
    }
</script>
