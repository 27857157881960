var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.loaded
        ? [
            _c("h2", { staticClass: "col12" }, [_vm._v("Kunden bearbeiten")]),
            _vm._v(" "),
            _c(
              "b-field",
              { staticClass: "col12", attrs: { label: "Name" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.client.name,
                    callback: function($$v) {
                      _vm.$set(_vm.client, "name", $$v)
                    },
                    expression: "client.name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              { staticClass: "col12", attrs: { label: "Ansprechpartner" } },
              [
                _c(
                  "b-select",
                  {
                    model: {
                      value: _vm.client.invoice_user_id,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "invoice_user_id", $$v)
                      },
                      expression: "client.invoice_user_id"
                    }
                  },
                  _vm._l(_vm.adminUsers, function(user, index) {
                    return _c("option", { domProps: { value: user.id } }, [
                      _vm._v(_vm._s(user.name))
                    ])
                  }),
                  0
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "col12",
                attrs: { label: "Datenschutzbeauftragter" }
              },
              [
                _c(
                  "b-select",
                  {
                    model: {
                      value: _vm.client.active_admin,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "active_admin", $$v)
                      },
                      expression: "client.active_admin"
                    }
                  },
                  _vm._l(_vm.adminUsers, function(user, index) {
                    return _c("option", { domProps: { value: user.id } }, [
                      _vm._v(_vm._s(user.name))
                    ])
                  }),
                  0
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-button",
              { attrs: { type: "is-primary" }, on: { click: _vm.saveClient } },
              [_vm._v("Speichern")]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }