var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "methodLocationList__data" },
    [
      _c(
        "b-field",
        [
          _vm.editMode
            ? _c(
                "b-select",
                {
                  ref: "location",
                  attrs: { placeholder: "Location", required: "" },
                  model: {
                    value: _vm.myMethodLocation.location,
                    callback: function($$v) {
                      _vm.$set(_vm.myMethodLocation, "location", $$v)
                    },
                    expression: "myMethodLocation.location"
                  }
                },
                [
                  _c("option", [_vm._v("Personenstammdaten")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Kommunikationsdaten")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Vertragsstammdaten")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Kundenhistorie")]),
                  _vm._v(" "),
                  _c("option", [
                    _vm._v("Vertragsabrechnungs- und Zahlungsdaten")
                  ]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Planungs- und Steuerungsdaten")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Auskunftsangaben")])
                ]
              )
            : _c("label", [_vm._v(_vm._s(_vm.myMethodLocation.location))])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        [
          _vm.editMode
            ? _c(
                "b-select",
                {
                  ref: "type",
                  attrs: { placeholder: "Type", required: "" },
                  model: {
                    value: _vm.myMethodLocation.type,
                    callback: function($$v) {
                      _vm.$set(_vm.myMethodLocation, "type", $$v)
                    },
                    expression: "myMethodLocation.type"
                  }
                },
                [
                  _c("option", [_vm._v("Kunden")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Interessenten")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Abonnenten")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Beschäftigte")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Auszubildende")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Bewerber")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Lieferanten")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Handelsvertreter")]),
                  _vm._v(" "),
                  _c("option", [_vm._v("Ansprechpartner")])
                ]
              )
            : _c("label", [_vm._v(_vm._s(_vm.myMethodLocation.type))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }