var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "methodLocationList" },
    [
      _c(
        "div",
        { staticClass: "methodLocationList__head" },
        [
          _c("b-field", { attrs: { label: "Art der Daten" } }),
          _vm._v(" "),
          _c("b-field", { attrs: { label: "Kategorie" } })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.myMethodLocations, function(methodLocation, index) {
        return _c(
          "div",
          {
            key: "method-location-" + methodLocation.id,
            staticClass: "methodLocationList__row"
          },
          [
            _c("method-location-element", {
              ref: "mu-" + index,
              refInFor: true,
              attrs: {
                "method-location": _vm.myMethodLocations[index],
                "can-edit": _vm.editMode.includes(index)
              },
              on: {
                "update:methodLocation": function($event) {
                  return _vm.$set(_vm.myMethodLocations, index, $event)
                },
                "update:method-location": function($event) {
                  return _vm.$set(_vm.myMethodLocations, index, $event)
                }
              },
              model: {
                value: _vm.myMethodLocations[index],
                callback: function($$v) {
                  _vm.$set(_vm.myMethodLocations, index, $$v)
                },
                expression: "myMethodLocations[index]"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "methodLocationList__action" }, [
              _c("span", { staticClass: "cursor-pointer" }, [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.editMode.includes(index) && !_vm.cannotEdit,
                      expression: "!editMode.includes(index) && !cannotEdit"
                    }
                  ],
                  staticClass: "fas fa-edit col3",
                  on: {
                    click: function($event) {
                      return _vm.setEditMode(index)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "cursor-pointer" }, [
                !_vm.cannotEdit && _vm.myMethodLocations.length > 1
                  ? _c("i", {
                      staticClass: "fas fa-trash-alt col4",
                      on: {
                        click: function($event) {
                          return _vm.deleteMethodLocation(index)
                        }
                      }
                    })
                  : _vm._e()
              ])
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      !_vm.cannotEdit
        ? _c(
            "button",
            {
              staticClass: "button has-margin-top-md",
              attrs: { disabled: _vm.cannotEdit },
              on: { click: _vm.addMethodLocation }
            },
            [_c("b-icon", { attrs: { icon: "folder-plus" } })],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }