<template>
    <section>
        <div class="is-flex items-center justify-between has-margin-bottom-sm">
            <h3 class="has-margin-none">Aktuelle Verfahren ({{total}})</h3>
            <div class="is-flex">
                <b-select class="has-margin-right-md" placeholder="Filter" icon="filter" v-model="filter" @input="loadData">
                    <option value="" selected>Alle</option>
                    <option value="active">Aktiv</option>
                    <option value="open">Offen</option>
                    <option value="expired">Abgelaufen</option>
                    <option value="soon">Bald Abgelaufen</option>
                </b-select>
                <b-select placeholder="Sortierung" icon="sort-amount-down" v-model="sort" @input="loadData">
                        <option value="title_asc" selected>Name aufsteigend</option>
                        <option value="title_desc">Name absteigend</option>
                        <option value="date_valid_from_asc">Startdatum aufsteigend</option>
                        <option value="date_valid_from_desc">Startdatum absteigend</option>
                        <option value="date_valid_to_asc">Ablaufdatum aufsteigend</option>
                        <option value="date_valid_to_desc">Ablaufdatum absteigend</option>
                        <option value="status_asc">Status</option>
                    </b-select>
            </div>
        </div>
        <b-table
            :data="data"
            :paginated="total > perPage"
            backend-pagination
            backend-sorting
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            :current-page="currentPage"
            aria-next-label="Nächste Seite"
            aria-previous-label="Vorherige Seite"
            aria-page-label="Seite"
            aria-current-label="Aktuelle Seite"
            custom-row-key="id"
            :loading="isLoading"
        >
            <template slot-scope="props">
                <b-table-column field="title" label="Titel" width="475">
                    {{ props.row.title }}
                </b-table-column>
                <b-table-column field="status" label="Status" width="250">
                    <status-tag :status="props.row.status" :key="props.row.id"/>
                </b-table-column>
                <b-table-column field="id" label="Aktion" class="text-align-right">
                    <button class="button" @click="openEditorModal(props.row.id)">
                        <b-icon icon="edit"/>
                    </button>
                </b-table-column>
            </template>
            <template slot="empty">
                <div class="table--empty">
                    <p>Keine Verfahren gefunden.</p>
                </div>
            </template>
        </b-table>
        <b-modal
            :active.sync="isMethodEditorModalActive"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
            v-show="!this.$root.$data.loadingIndicator"
        >
            <method-editor :contract-id="contractId" :method-id="selectedMethod" @reloadMethodList="reloadData" :canceled="canceled"/>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios';
    import {Select, Table, Modal} from 'buefy';
    import StatusTag from './StatusTag';
    import MethodEditor from './MethodEditor';

    export default {
        name: 'MethodList',
        components: {Select, Table, StatusTag, Modal, MethodEditor},
        data: function() {
            return {
                data: [],
                currentPage: 1,
                total: 0,
                perPage: 10,
                prevPageUrl: null,
                nextPageUrl: null,
                lastPageUrl: null,
                firstPageUrl: null,
                filter: '',
                sort: 'title_asc',
                isLoading: true,
                selectedMethod: null,
                isMethodEditorModalActive: false,
            }
        },
        props: ['contractId', 'canceled'],
        created() {
            this.loadData();
        },
        methods: {
            /**
             * Loads the data from the backend.
             *
             * @param resetPage will load data from the first page if set to true.
             */
            loadData(resetPage = true) {
                this.isLoading = true;
                if(resetPage) {
                    this.currentPage = 1;
                }
                let url = '/method/get/byContractId/' + this.contractId + '?page=' + this.currentPage;
                axios.get(url, {params: {perPage: this.perPage, filter: this.filter, orderBy: this.sort}})
                .then((response) => {
                    if(!response.data.error) {
                        this.data = [];
                        this.total = response.data.total;
                        this.prevPageUrl = response.data.prev_page_url;
                        this.nextPageUrl = response.data.next_page_url;
                        this.lastPageUrl = response.data.last_page_url;
                        this.firstPageUrl = response.data.first_page_url;
                        response.data.data.forEach((method) => {
                            this.data.push({title: method.title, status: method.status, id: method.id});
                        });
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            /**
             * Handle page change.
             *
             * @param page
             */
            onPageChange(page) {
                this.currentPage = page;
                this.loadData(false);
            },
            /**
             * Opens the editor modal.
             *
             * @param id the method ID to pass to the editor.
             */
            openEditorModal(id) {
                this.selectedMethod = id;
                this.isMethodEditorModalActive = true;
            },
            /**
             * Reloads the data from the backend and fires an event to let the parent component know.
             */
            reloadData()
            {
                this.$emit('reloadData');
                this.loadData();
            }
        }
    }
</script>

<style scoped lang="scss">
    .methodTableControl {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        h3 {
            margin: 0;
        }
    }
    .methodList {
        grid-row: span 2;
        align-self: flex-start;
    }
    .text-align-right {
        text-align: right;
    }
</style>
