<template>
    <section>
        <div class="is-flex justify-between items-center">
            <h2>Standorte</h2>
            <b-switch v-model="showCanceledContracts">Beendete Verträge anzeigen</b-switch>
        </div>
        <b-collapse
            class="card show"
            v-for="(location, index) of locations"
            :key="index"
            :open="isOpen === index"
            @close="isOpen = null"
            @open="isOpen = index"
            >
            <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                :class="isOpen === index ? 'collapse-open' : ''"
                role="button"
            >
                <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'caret-right' : 'caret-down'" custom-class="color-thomann-blue"/>
                </a>
                <p class="has-text-centered align-self-center">
                    {{location.name}} <small class="is-light">({{numberContracts(location)}} {{numberContracts(location) === 1 ? 'Vertrag' : 'Verträge'}})</small>
                </p>
            </div>
            <div class="card-content">
                <div class="content" v-for="contract in location.contracts" v-if="contract.status === 'active' || (contract.status === 'canceled' && showCanceledContracts)">
                    <span>
                        <span class="arrow"><i class="fas fa-long-arrow-alt-right"></i></span>
                        {{contract.contractor.name}}
                        <small class="is-light">({{contract.methods ? contract.methods.length : 0}} Verfahren, {{contract.subs ? contract.subs.length : 0}} Subs)</small>
                        <small class="is-light" v-if="contract.status === 'canceled'">(Beendet)</small>
                    </span>
                    <b-button @click="openContract(contract.id)"><b-icon icon="edit"/></b-button>
                </div>
            </div>
        </b-collapse>
    </section>
</template>

<script>
    import axios from 'axios';
    import {Collapse, Icon, Button} from 'buefy';

    export default {
        name: "LocationsOverview",
        components: {
            Collapse,
            Icon,
            Button
        },
        data: function() {
            return {
                locations: null,
                isOpen: null,
                showCanceledContracts: false,
            };
        },
        created() {
            axios.get('/location/all')
                .then((response) => {
                    if(!response.data.error) {
                        this.locations = response.data;
                        if(this.locations.length === 1) {
                            this.isOpen = 0;
                        }
                    }
            });
        },
        methods: {
            /**
             * Redirect to the selected contract.
             *
             * @param contractId
             */
            openContract(contractId) {
                this.$router.push({name: 'contract', params: {contractId: contractId}});
            },
            /**
             * Compute the number of contracts depending if canceled contracts should be shown.
             *
             * @param location
             * @returns {*}
             */
            numberContracts(location) {
                if(this.showCanceledContracts) {
                    return location.contracts.length;
                }
                return location.contracts.filter(contract => contract.status === 'active').length;
            }
        }
    }
</script>

<style scoped lang="scss">
.flexContainer {
    display: flex;
    justify-content: flex-end;
}
</style>
