<template>
    <section>
        <span :class="tagClass">
            {{getNicename}}
        </span>
    </section>
</template>

<script>
    export default {
        name: 'StatusTag',
        props: ['status'],
        data: function() {
            return {
                tagClass: '',
                nicename: '',
            }
        },
        computed: {
            getNicename() {
                if('open' === this.status) {
                    this.tagClass = 'tag is-rounded is-light';
                    this.nicename = 'Offen';
                    return this.nicename;
                }
                if('active' === this.status) {
                    this.tagClass = 'tag is-rounded is-light is-success';
                    this.nicename = 'Aktiv';
                    return this.nicename;
                }
                if('expired' === this.status) {
                    this.tagClass = 'tag is-rounded is-light is-danger';
                    this.nicename = 'Abgelaufen';
                    return this.nicename;
                }
            }
        }
    }
</script>

<style scoped>

</style>
