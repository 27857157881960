var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("Freigaben")]),
      _vm._v(" "),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.tableData,
            "custom-row-key": "id",
            paginated: _vm.total > 5,
            "per-page": "5",
            "current-page": _vm.currentPage,
            total: _vm.total,
            "backend-pagination": "",
            loading: _vm.isLoading
          },
          on: {
            "update:currentPage": function($event) {
              _vm.currentPage = $event
            },
            "update:current-page": function($event) {
              _vm.currentPage = $event
            },
            "page-change": _vm.onPageChange
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { field: "title", label: "Titel" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.title) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { field: "title", label: "Parteien" } },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(props.row.parties) }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { field: "status", label: "Status" } },
                    [_c("status-tag", { attrs: { status: props.row.status } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      staticClass: "has-text-right",
                      attrs: { field: "contract_id", label: "" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.openEditorModal(
                                props.row.id,
                                props.row.contract_id
                              )
                            }
                          }
                        },
                        [_c("b-icon", { attrs: { icon: "edit" } })],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "table--empty" }, [
              _c("p", [_vm._v("Keine Verfahren gefunden.")])
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$root.$data.loadingIndicator,
              expression: "!this.$root.$data.loadingIndicator"
            }
          ],
          attrs: {
            active: _vm.isMethodEditorModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isMethodEditorModalActive = $event
            }
          }
        },
        [
          _c("method-editor", {
            attrs: {
              "contract-id": _vm.contractId,
              "method-id": _vm.selectedMethod
            },
            on: { reloadMethodList: _vm.loadData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }