var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "is-flex justify-between items-center" },
        [
          _c("h2", [_vm._v("Standorte")]),
          _vm._v(" "),
          _c(
            "b-switch",
            {
              model: {
                value: _vm.showCanceledContracts,
                callback: function($$v) {
                  _vm.showCanceledContracts = $$v
                },
                expression: "showCanceledContracts"
              }
            },
            [_vm._v("Beendete Verträge anzeigen")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.locations, function(location, index) {
        return _c(
          "b-collapse",
          {
            key: index,
            staticClass: "card show",
            attrs: { open: _vm.isOpen === index },
            on: {
              close: function($event) {
                _vm.isOpen = null
              },
              open: function($event) {
                _vm.isOpen = index
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "trigger",
                  fn: function(props) {
                    return _c(
                      "div",
                      {
                        staticClass: "card-header",
                        class: _vm.isOpen === index ? "collapse-open" : "",
                        attrs: { role: "button" }
                      },
                      [
                        _c(
                          "a",
                          { staticClass: "card-header-icon" },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: props.open ? "caret-right" : "caret-down",
                                "custom-class": "color-thomann-blue"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "has-text-centered align-self-center"
                          },
                          [
                            _vm._v(
                              "\n                " + _vm._s(location.name) + " "
                            ),
                            _c("small", { staticClass: "is-light" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.numberContracts(location)) +
                                  " " +
                                  _vm._s(
                                    _vm.numberContracts(location) === 1
                                      ? "Vertrag"
                                      : "Verträge"
                                  ) +
                                  ")"
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  }
                }
              ],
              null,
              true
            )
          },
          [
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-content" },
              _vm._l(location.contracts, function(contract) {
                return contract.status === "active" ||
                  (contract.status === "canceled" && _vm.showCanceledContracts)
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("span", [
                          _c("span", { staticClass: "arrow" }, [
                            _c("i", {
                              staticClass: "fas fa-long-arrow-alt-right"
                            })
                          ]),
                          _vm._v(
                            "\n                    " +
                              _vm._s(contract.contractor.name) +
                              "\n                    "
                          ),
                          _c("small", { staticClass: "is-light" }, [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  contract.methods ? contract.methods.length : 0
                                ) +
                                " Verfahren, " +
                                _vm._s(
                                  contract.subs ? contract.subs.length : 0
                                ) +
                                " Subs)"
                            )
                          ]),
                          _vm._v(" "),
                          contract.status === "canceled"
                            ? _c("small", { staticClass: "is-light" }, [
                                _vm._v("(Beendet)")
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openContract(contract.id)
                              }
                            }
                          },
                          [_c("b-icon", { attrs: { icon: "edit" } })],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              }),
              0
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }