<template>
    <section>
        <client-list></client-list>
    </section>
</template>

<script>
    import ClientList from "../components/admin/ClientList";
    export default {
        name: 'AdminDashboard',
        components: {
            ClientList,
        }
    }
</script>
