var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboardGrid" },
    [
      _vm.isAdmin() || _vm.isSuperAdmin()
        ? _c("open-methods", { staticClass: "has-margin-bottom-lg" })
        : _vm._e(),
      _vm._v(" "),
      _c("locations-overview", { staticClass: "has-margin-bottom-xl" }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          staticClass: "modalOverflowVisible",
          attrs: {
            active: _vm.isContractCreatorActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isContractCreatorActive = $event
            }
          }
        },
        [_c("contract-creator")],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _vm.isManager() || _vm.isAdmin()
          ? _c(
              "button",
              {
                staticClass: "button is-primary",
                on: {
                  click: function($event) {
                    _vm.isContractCreatorActive = true
                  }
                }
              },
              [_vm._v("Vertrag hinzufügen")]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }