<template>
    <div class="dashboardGrid">
        <open-methods class="has-margin-bottom-lg" v-if="isAdmin() || isSuperAdmin()"/>
        <locations-overview class="has-margin-bottom-xl" />
        <b-modal
            :active.sync="isContractCreatorActive"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
            class="modalOverflowVisible"
        >
            <contract-creator/>
        </b-modal>
        <div>
            <button class="button is-primary" @click="isContractCreatorActive = true" v-if="isManager() || isAdmin()">Vertrag hinzufügen</button>
        </div>
    </div>
</template>

<script>
    import ContractCreator from '../components/ContractCreator';
    import OpenMethods from '../components/OpenMethods';
    import LocationsOverview from '../components/LocationsOverview';
    export default {
        name: "Dashboard",
        components: {ContractCreator, OpenMethods, LocationsOverview},
        data() {
            return {
                isContractCreatorActive: false,
            }
        }
    }
</script>

<style scoped>
    .dashboardGrid {
        display: grid;
        grid-template-columns: 1fr;
    }
</style>
