<template>
    <section class="contractCreator">
        <h2 class="full-row has-margin-bottom-md">Neuen Vertrag anlegen</h2>
        <b-field class="grid-col-1" label="Auftraggeber">
            <b-select placeholder="Auftraggeber..." v-model="selectedCustomer" @input="loadContractors" expanded>
                <option v-for="customer in customers" :value="customer.id" :key="customer.id">{{customer.name}}</option>
            </b-select>
        </b-field>
        <span class="grid-col-2 has-padding-none arrow" style="align-self: flex-end;"><i class="fas fa-long-arrow-alt-right"></i></span>
        <b-field class="grid-col-3" label="Auftragnehmer">
            <b-select placeholder="Auftragnehmer..." v-model="selectedContractor" :disabled="!contractors.length" expanded>
                <option v-for="contractor in contractors" :value="contractor.id" :key="contractor.id">{{contractor.name}}</option>
            </b-select>
        </b-field>
        <p class="full-row has-margin-bottom-md has-text-danger" v-if="noMoreContractors">
            Dieser Auftraggeber hat bereits Verträge mit allen verfügbaren Auftragnehmern geschlossen.
        </p>
        <b-field class="grid-col-1 has-margin-bottom-sm" label="Subs">
            <ul>
                <li v-for='sub in subs'>
                    <b-checkbox
                        v-model='selectedSubs[sub.id]'
                        :key="sub.id"
                    >{{sub.name}}</b-checkbox>
                </li>
            </ul>
        </b-field>
        <b-field class="grid-col-3" label="Gültig ab">
            <b-datepicker v-model="date_valid_from"/>
        </b-field>
        <div class="full-row">
            <b-button type="is-primary" @click="createContract" :disabled="!(selectedCustomer && selectedContractor)">Speichern</b-button>
        </div>
    </section>
</template>

<script>
    import SubList from './SubList';
    export default {
        name: 'ContractCreator',
        components: {SubList},
        data() {
            return {
                customers: [],
                selectedCustomer: null,
                contractors: [],
                selectedContractor: null,
                subs: [],
                selectedSubs: [],
                date_valid_from: new Date(),
                noMoreContractors: false,
            }
        },
        created() {
            axios.all([this.getLoadCustomerRequest(), this.getLoadSubsRequest()]).then(axios.spread((customers, subs) => {
                if(customers.data.error || subs.data.error) {
                    return;
                }
                this.$set(this, 'customers', customers.data);
                this.$set(this, 'subs', subs.data);
            }));
        },
        methods: {
            /**
             * Load list of possible contractors.
             */
            loadContractors()
            {
                this.noMoreContractors = false;
                this.contractors = [];
                this.selectedContractor = null;
                axios.all([this.getLoadContractorsRequest()]).then(axios.spread((contractors) => {
                    if(contractors.data.error) {
                        return;
                    } else {
                        if(!contractors.data.length) {
                            this.noMoreContractors = true;
                        }
                        this.$set(this, 'contractors', contractors.data);
                    }
                }));
            },
            /**
             * Get request to load all locations.
             *
             * @returns {*}
             */
            getLoadCustomerRequest()
            {
                return axios.get('/location/all');
            },
            /**
             * Get request to load all possible contractors.
             *
             * @returns {*}
             */
            getLoadContractorsRequest()
            {
                return axios.get('/location/possible/' + this.selectedCustomer);
            },
            /**
             * Get request to load sublocations.
             *
             * @returns {*}
             */
            getLoadSubsRequest()
            {
                return axios.get('/location/subs');
            },
            /**
             * Handle new contract creation.
             */
            createContract()
            {
                let subIds = [];
                this.selectedSubs.forEach((sub, index) => {
                    if(sub) {
                        subIds.push(index);
                    }
                });
                axios.post('/contract/create', {
                    contract: {
                        customer_id: this.selectedCustomer,
                        contractor_id: this.selectedContractor,
                        subs: subIds,
                        start_date: this.date_valid_from.toDateString(),
                    }}).then((response) => {
                    if(response.data.error) {
                        this.$buefy.notification.open({
                            type: 'is-danger',
                            message: 'Der Vertrag konnte nicht angelegt werden: \n' + response.data.error,
                            position: 'is-top-right',
                            duration: 3500,
                        });
                        return;
                    }
                    if(response.data.id) {
                        this.$buefy.notification.open({
                            type: 'is-success',
                            message: 'Der Vertrag wurde erfolgreich angelegt.',
                            position: 'is-top-right',
                            duration: 3500,
                        });
                        this.$router.push('/contract/' + response.data.id);
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    .contractCreator {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: auto;
        grid-gap: 2rem;

        .full-row {
            grid-column: 1 / span 3;
        }

        .grid-col-1 {
            grid-column: 1;
        }

        .grid-col-2 {
            grid-column: 2;
        }

        .grid-col-3 {
            grid-column: 3;
        }

        .dropdown {
            min-width: 250px;
        }
    }
</style>
