<template>
    <section>
        <div class="is-flex items-center justify-between has-margin-bottom-sm">
            <h3 v-if="type === 'location'" class="has-margin-none">Standorte</h3>
            <h3 v-if="type === 'sub'" class="has-margin-none">Sub-Standorte</h3>
            <div class="locationListControl">
                <b-button type="is-primary" @click="onAddLocationButtonClick">Neuen Standort anlegen</b-button>
            </div>
        </div>
        <b-table
            class="has-margin-bottom-lg"
            :data="locations"
            :paginated="total > perPage"
            backend-pagination
            backend-filtering
            backend-sorting
            :total="total"
            :per-page="perPage"
            @pageChange="onPageChange"
            :current-page="currentPage"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            custom-row-key="id"
            :loading="isLoading"
        >
            <template slot-scope="props">
                <b-table-column field="name" label="Name">
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column field="address" label="Adresse">
                    {{ props.row.address }}
                </b-table-column>
                <b-table-column field="id" label="Aktionen" class="has-text-right">
                    <b-button @click="openEditorModal(props.row.id)"><b-icon icon="edit"/></b-button>
                    <b-button type="is-danger" @click="openConfirmDeleteDialog(props.row.id)"><b-icon type="white" icon="trash"/></b-button>
                </b-table-column>
            </template>
            <template slot="empty">
                <div class="table--empty">
                    <p>Keine Standorte gefunden.</p>
                </div>
            </template>
        </b-table>

        <b-modal
            :active.sync="isLocationEditorModalActive"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-modal
            v-show="!this.$root.$data.loadingIndicator"
        >
            <location-editor :location-id="selectedLocation" :type="type" @reloadLocationList="loadData"/>
        </b-modal>
    </section>
</template>

<script>
    import LocationEditor from "./LocationEditor";
    import {EventBus} from "../../eventbus";
    export default {
        name: 'LocationList',
        components: {LocationEditor},
        props: {
            type: {
                type: String,
                required: true,
                default: function() {
                    return 'location'
                }
            }
        },
        data() {
            return {
                locations: [],
                isLoading: true,
                total: 0,
                perPage: 10,
                currentPage: 1,
                selectedLocation: null,
                isLocationEditorModalActive: false,
            }
        },
        computed: {
            /**
             * Returns the url for the location list.
             */
            locationListUrl() {
                if(this.type === 'location') {
                    return '/location/all'
                } else if(this.type === 'sub') {
                    return '/location/subs'
                }
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            /**
             * Loads the data from the backend.
             *
             * @param resetPage
             */
            loadData(resetPage = true)
            {
                this.isLoading = true;
                if(resetPage) {
                    this.currentPage = 1;
                }
                EventBus.$emit('setLoadingIndicator');
                this.getLoadDataRequest().then((response) => {
                    if(response.data.error) {
                        return;
                    }
                    this.$set(this, 'locations', response.data.data);
                    this.total = response.data.total;
                }).finally(() => {
                    this.isLoading = false;
                    EventBus.$emit('unsetLoadingIndicator');
                })
            },
            /**
             * Returns the request to load the locations.
             */
            getLoadDataRequest()
            {
                return axios.get(this.locationListUrl, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage,
                    }
                });
            },
            /**
             * Page change handler.
             *
             * @param page
             */
            onPageChange(page)
            {
                this.currentPage = page;
                this.loadData(false);
            },
            /**
             * Opens the editor modal for the given location ID.
             *
             * @param id
             */
            openEditorModal(id)
            {
                this.selectedLocation = id;
                this.isLocationEditorModalActive = true;
            },
            /**
             * Handler for the delete location button.
             *
             * @param id
             */
            openConfirmDeleteDialog(id)
            {
                this.$buefy.dialog.confirm({
                    title: 'Standort löschen',
                    message: 'Sind Sie sicher, dass Sie den Standort löschen wollen?',
                    confirmText: 'Löschen',
                    cancelText: 'Abbrechen',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        EventBus.$emit('setLoadingIndicator');
                        axios.delete('/location/delete/' + id).then((response) => {
                        if(!response.data.error) {
                            this.$buefy.notification.open({
                                message: 'Der Standort wurde erfolgreich gelöscht.',
                                type: 'is-success',
                                position: 'is-top-right',
                                duration: 3500,
                            })
                        }
                    }).finally(() => {
                        EventBus.$emit('unsetLoadingIndicator');
                        this.loadData();
                    })}
                })
            },
            /**
             * Handler for the new location button.
             */
            onAddLocationButtonClick()
            {
                this.selectedLocation = null;
                this.openEditorModal(null);
            }
        }
    }
</script>
