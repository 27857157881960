var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      [
        _c("h2", { staticClass: "col12" }, [_vm._v("Kunden anlegen")]),
        _vm._v(" "),
        _c(
          "b-field",
          { staticClass: "col12", attrs: { label: "Kundenname" } },
          [
            _c("b-input", {
              attrs: { required: "" },
              model: {
                value: _vm.clientname,
                callback: function($$v) {
                  _vm.clientname = $$v
                },
                expression: "clientname"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { staticClass: "col12", attrs: { label: "Ansprechpartnername" } },
          [
            _c("b-input", {
              attrs: { required: "" },
              model: {
                value: _vm.username,
                callback: function($$v) {
                  _vm.username = $$v
                },
                expression: "username"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { staticClass: "col12", attrs: { label: "Ansprechpartneremail" } },
          [
            _c("b-input", {
              attrs: { type: "email", required: "" },
              model: {
                value: _vm.useremail,
                callback: function($$v) {
                  _vm.useremail = $$v
                },
                expression: "useremail"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { staticClass: "col12", attrs: { label: "Standortname" } },
          [
            _c("b-input", {
              attrs: { required: "" },
              model: {
                value: _vm.locationname,
                callback: function($$v) {
                  _vm.locationname = $$v
                },
                expression: "locationname"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { staticClass: "col12", attrs: { label: "Standortadresse" } },
          [
            _c("b-input", {
              attrs: { required: "" },
              model: {
                value: _vm.locationaddress,
                callback: function($$v) {
                  _vm.locationaddress = $$v
                },
                expression: "locationaddress"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.createClient } },
          [_vm._v("Anlegen")]
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }