<template>
    <section>
        <template>
            <h2 class="col12">Kunden anlegen</h2>
            <b-field label="Kundenname" class="col12">
                <b-input v-model="clientname" required/>
            </b-field>
            <b-field label="Ansprechpartnername" class="col12">
                <b-input v-model="username" required/>
            </b-field>
            <b-field label="Ansprechpartneremail" class="col12">
                <b-input type="email" v-model="useremail" required/>
            </b-field>
            <b-field label="Standortname" class="col12">
                <b-input v-model="locationname" required/>
            </b-field>
            <b-field label="Standortadresse" class="col12">
                <b-input v-model="locationaddress" required/>
            </b-field>
            <b-button type="is-primary" @click="createClient">Anlegen</b-button>
        </template>
    </section>
</template>

<script>
    import {EventBus} from '../../eventbus';

    export default {
        name: 'ClientCreator',
        data() {
            return {
                clientname: '',
                username: '',
                useremail: '',
                locationname: '',
                locationaddress: '',
            }
        },
        methods: {
            createClient()
            {
                EventBus.$emit('setLoadingIndicator');
                axios.post('/admin/create/client', {
                    clientName: this.clientname,
                    userName: this.username,
                    userEmail: this.useremail,
                    locationName: this.locationname,
                    locationAddress: this.locationaddress,
                }).then((response) => {
                    EventBus.$emit('unsetLoadingIndicator');
                    if(response.data.error) {
                        return;
                    }
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Der Kunde wurde angelegt.'
                    });
                    this.$emit('loadData');
                    this.$parent.close();
                }).finally(() => {
                    EventBus.$emit('unsetLoadingIndicator');
                })
            },
            validate()
            {
                if(!this.clientname) {
                    this.$buefy.notification.open({
                        type: 'is-danger',
                        message: 'Der Kunde muss einen Namen haben.',
                        duration: 5000
                    });
                    return false;
                }
                if(!this.username) {
                    this.$buefy.notification.open({
                        type: 'is-danger',
                        message: 'Der Kundenansprechpartner muss einen Namen haben.',
                        duration: 5000
                    });
                    return false;
                }
                if(!this.useremail) {
                    this.$buefy.notification.open({
                        type: 'is-danger',
                        message: 'Der Kundenansprechpartner muss eine Email haben.',
                        duration: 5000
                    });
                    return false;
                }

                return true;
            }
        }
    }
</script>

<style scoped>

</style>
