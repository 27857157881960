var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "is-grid gap-2" },
    [
      _c("h2", [_vm._v("Benutzerprofil")]),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Name" } },
        [
          _c("b-input", {
            ref: "nameInput",
            attrs: { required: "" },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Email" } },
        [
          _c("b-input", {
            ref: "emailInput",
            attrs: { type: "email", required: "" },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Altes Passwort" } },
        [
          _c("b-input", {
            ref: "oldPasswordInput",
            attrs: { type: "password" },
            model: {
              value: _vm.oldPassword,
              callback: function($$v) {
                _vm.oldPassword = $$v
              },
              expression: "oldPassword"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Neues Passwort" } },
        [
          _c("b-input", {
            ref: "newPasswordInput",
            attrs: { type: "password" },
            model: {
              value: _vm.newPassword,
              callback: function($$v) {
                _vm.newPassword = $$v
              },
              expression: "newPassword"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: "Neues Passwort (Wiederholung)" } },
        [
          _c("b-input", {
            ref: "newPasswordConfirmInput",
            attrs: { type: "password" },
            model: {
              value: _vm.newPasswordConfirm,
              callback: function($$v) {
                _vm.newPasswordConfirm = $$v
              },
              expression: "newPasswordConfirm"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-field",
        [
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: { click: _vm.onSaveButtonClick }
            },
            [_vm._v("Speichern")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }