<template>
    <section class="user-editor">
        <template v-if="loaded">
            <h2 v-if="user.id">Benutzer bearbeiten</h2>
            <h2 v-else>Benutzer anlegen</h2>
            <b-field label="Name">
                <b-input v-model="user.name" required ref="userName" use-html5-validation/>
            </b-field>
            <b-field label="Email">
                <b-input v-model="user.email" type="email" required ref="userEmail" use-html5-validation/>
            </b-field>
            <b-field label="Rolle">
                <b-select @input="onRoleSelect" placeholder="Rolle" v-model="selectedRole" expanded required ref="userRole" use-html5-validation>
                    <option v-for="role in possibleRoles" :value="role">{{role}}</option>
                </b-select>
            </b-field>
            <b-field label="Standort" v-if="!isSuperadmin">
                <b-select @input="onLocationSelect" placeholder="Standort" v-model="selectedLocation" expanded required ref="userLocation" use-html5-validation>
                    <option v-for="location in possibleLocations" :value="location.id">{{location.name}}</option>
                </b-select>
            </b-field>
            <b-button class="is-fullwidth" @click="resetPassword" v-if="user.id">Passwort zurücksetzen</b-button>
            <b-button class="is-fullwidth" type="is-primary" @click="onSaveButtonClick">Speichern</b-button>
        </template>
    </section>
</template>

<script>
    import {EventBus} from "../../eventbus";

    export default {
        name: 'UserEditor',
        props: ['userId'],
        data() {
            return {
                loaded: false,
                user: null,
                possibleRoles: [],
                possibleLocations: [],
                selectedRole: null,
                selectedLocation: null,
                createNewUser: false,
            }
        },
        created() {
            EventBus.$emit('setLoadingIndicator');
            axios.all([this.getUserRequest(), this.getUserRolesRequest(), this.getLocationsRequest()]).then(axios.spread((user, roles, locations) => {
                if(user) {
                    this.user = user.data;
                    this.selectedRole = this.user.roles[0].name;
                    this.selectedLocation = this.user.location.id;
                } else {
                    this.user = {
                        name: '',
                        email: '',
                        location_id: null,
                        roles: [],
                    };
                    this.createNewUser = true;
                }

                roles.data.forEach((role) => {
                    this.possibleRoles.push(role);
                });
                locations.data.forEach((location) => {
                    this.possibleLocations.push(location);
                })
            })).finally(() => {
                this.loaded = true;
                EventBus.$emit('unsetLoadingIndicator');
            });
        },
        computed: {
            isSuperadmin()
            {
                return this.$root.$data.roles.includes('superadmin');
            }
        },
        methods: {
            /**
             * Get the request to load a single user.
             */
            getUserRequest() {
                if(this.userId) {
                    return axios.get('/user/get/' + this.userId);
                }
                return null;
            },
            /**
             * Get the request to load possible user roles.
             */
            getUserRolesRequest() {
                return axios.get('/user/possibleRoles');
            },
            /**
             * Get the request to load possible locations for the user.
             */
            getLocationsRequest() {
                return axios.get('/location/all');
            },
            /**
             * Role select handler.
             *
             * @param role
             */
            onRoleSelect(role) {
                this.selectedRole = role;
            },
            /**
             * Location select handler.
             *
             * @param location
             */
            onLocationSelect(location) {
                this.selectedLocation = location;
            },
            /**
             * Save button click handler.
             */
            onSaveButtonClick()
            {
                if(this.createNewUser) {
                    this.createUser();
                } else {
                    this.saveUser();
                }
            },
            /**
             * Handle creation of a new user.
             */
            createUser()
            {
                if(!this.validateInput()) {
                    return;
                }
                let newUser = _.cloneDeep(this.user);
                newUser.location_id = this.selectedLocation;
                newUser.roles.push(this.selectedRole);
                EventBus.$emit('setLoadingIndicator');
                axios.put('/user/create', {
                    user: newUser,
                }).then((response) => {
                    if(response.data.error) {
                        return;
                    }
                    this.$emit('reloadUserList');
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Der Benutzer wurde erfolgreich angelegt.'
                    });
                }).finally(() => {
                    EventBus.$emit('unsetLoadingIndicator');
                    this.$parent.close();
                })
            },
            /**
             * Handle update of a user.
             */
            saveUser()
            {
                if(!this.validateInput()) {
                }
                EventBus.$emit('setLoadingIndicator');
                axios.patch('/user/edit', {
                    user: this.user,
                    newRole: this.selectedRole,
                    newLocation: this.selectedLocation,
                }).then((response) => {
                    if(response.data.error) {
                        this.$buefy.notification.open({
                            type: 'is-danger',
                            message: 'Der Benutzer konnte nicht bearbeitet werden.'
                        });
                        return;
                    }
                    this.$emit('reloadUserList');
                    this.$buefy.notification.open({
                        type: 'is-success',
                        message: 'Der Benutzer wurde erfolgreich bearbeitet.'
                    });
                }).finally(() => {
                    EventBus.$emit('unsetLoadingIndicator');
                    this.$parent.close();
                })
            },
            /**
             * Trigger password reset email for user.
             */
            resetPassword()
            {
                this.$buefy.dialog.confirm({
                    title: 'Passwort zurücksetzen',
                    message: 'Sind Sie sicher, dass Sie dem Benutzer eine Email zum Zurücksetzen des Passwortes schicken wollen?',
                    confirmText: 'Zurücksetzen',
                    cancelText: 'Abbrechen',
                    hasIcon: true,
                    onConfirm: () => {
                        EventBus.$emit('setLoadingIndicator');
                        axios.post('/user/reset/' + this.user.id).then((response) => {
                        if(!response.data.error) {
                            this.$emit('reloadUserList');
                            this.$buefy.notification.open({
                                type: 'is-success',
                                message: 'Der Benutzer bekommt eine Email zum Zurücksetzen des Passwortes.'
                            });
                        }
                    }).finally(() => {
                            this.$parent.close();
                            EventBus.$emit('unsetLoadingIndicator');
                        })
                    }
                })
            },
            /**
             * Validate form input.
             */
            validateInput()
            {
                if (
                    !this.$refs['userName'].checkHtml5Validity()
                    || !this.$refs['userEmail'].checkHtml5Validity()
                    || !this.$refs['userRole'].checkHtml5Validity()
                ) {
                    this.$buefy.toast.open({
                        message: 'Bitte überprüfen Sie Ihre Eingaben.',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                    return false;
                }

                if(!this.isSuperadmin) {
                    if(!this.$refs['userLocation'].checkHtml5Validity()) {
                        this.$buefy.toast.open({
                            message: 'Bitte überprüfen Sie Ihre Eingaben.',
                            position: 'is-bottom',
                            type: 'is-danger'
                        });
                        return false;
                    }
                }

                return true;
            }
        }
    }
</script>

<style lang="scss">
    .user-editor {
        display: grid;
        grid-gap: 2rem;
    }
</style>
