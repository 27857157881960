var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "div",
        {
          staticClass:
            "is-flex items-center justify-between has-margin-bottom-sm"
        },
        [
          _vm.type === "location"
            ? _c("h3", { staticClass: "has-margin-none" }, [
                _vm._v("Standorte")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "sub"
            ? _c("h3", { staticClass: "has-margin-none" }, [
                _vm._v("Sub-Standorte")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "locationListControl" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "is-primary" },
                  on: { click: _vm.onAddLocationButtonClick }
                },
                [_vm._v("Neuen Standort anlegen")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-table",
        {
          staticClass: "has-margin-bottom-lg",
          attrs: {
            data: _vm.locations,
            paginated: _vm.total > _vm.perPage,
            "backend-pagination": "",
            "backend-filtering": "",
            "backend-sorting": "",
            total: _vm.total,
            "per-page": _vm.perPage,
            "current-page": _vm.currentPage,
            "aria-next-label": "Next page",
            "aria-previous-label": "Previous page",
            "aria-page-label": "Page",
            "aria-current-label": "Current page",
            "custom-row-key": "id",
            loading: _vm.isLoading
          },
          on: { pageChange: _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { field: "name", label: "Name" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.name) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { field: "address", label: "Adresse" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.address) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      staticClass: "has-text-right",
                      attrs: { field: "id", label: "Aktionen" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openEditorModal(props.row.id)
                            }
                          }
                        },
                        [_c("b-icon", { attrs: { icon: "edit" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-danger" },
                          on: {
                            click: function($event) {
                              return _vm.openConfirmDeleteDialog(props.row.id)
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            attrs: { type: "white", icon: "trash" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "table--empty" }, [
              _c("p", [_vm._v("Keine Standorte gefunden.")])
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$root.$data.loadingIndicator,
              expression: "!this.$root.$data.loadingIndicator"
            }
          ],
          attrs: {
            active: _vm.isLocationEditorModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isLocationEditorModalActive = $event
            }
          }
        },
        [
          _c("location-editor", {
            attrs: { "location-id": _vm.selectedLocation, type: _vm.type },
            on: { reloadLocationList: _vm.loadData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }