var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v("Standortverwaltung")]),
      _vm._v(" "),
      _c("location-list", { attrs: { type: "location" } }),
      _vm._v(" "),
      _c("location-list", { attrs: { type: "sub" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }