export default {
    methods: {
        validateOnBlur(event, ref) {
            console.log(ref);
            console.log(this.$refs[ref]);
            this.$refs[ref].setInvalid();
        },
        resetValidation(event, ref) {
            this.$refs[ref].setValidity(true);
        }
    }
}
