var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "userList" },
    [
      _c(
        "div",
        { staticClass: "is-flex items-center justify-between" },
        [
          _c("h2", [_vm._v("Benutzerverwaltung")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "userListControl" },
            [
              _c(
                "b-select",
                {
                  attrs: { placeholder: "Filter", icon: "user-tag" },
                  on: { input: _vm.loadData },
                  model: {
                    value: _vm.roleFilter,
                    callback: function($$v) {
                      _vm.roleFilter = $$v
                    },
                    expression: "roleFilter"
                  }
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("Alle")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "admin" } }, [
                    _vm._v("Admin")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "manager" } }, [
                    _vm._v("Manager")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "user" } }, [_vm._v("User")])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-select",
                {
                  attrs: { placeholder: "Filter", icon: "building" },
                  on: { input: _vm.loadData },
                  model: {
                    value: _vm.locationFilter,
                    callback: function($$v) {
                      _vm.locationFilter = $$v
                    },
                    expression: "locationFilter"
                  }
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("Alle")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.locations, function(location) {
                    return _c("option", { domProps: { value: location.id } }, [
                      _vm._v(_vm._s(location.name))
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-select",
                {
                  attrs: {
                    placeholder: "Sortierung",
                    icon: "sort-amount-down"
                  },
                  on: { input: _vm.loadData },
                  model: {
                    value: _vm.orderBy,
                    callback: function($$v) {
                      _vm.orderBy = $$v
                    },
                    expression: "orderBy"
                  }
                },
                [
                  _c("option", { attrs: { value: "name_asc", selected: "" } }, [
                    _vm._v("Name aufsteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "name_desc" } }, [
                    _vm._v("Name absteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "location_asc" } }, [
                    _vm._v("Ort aufsteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "location_desc" } }, [
                    _vm._v("Ort absteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "role_asc" } }, [
                    _vm._v("Rolle aufsteigend")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "role_desc" } }, [
                    _vm._v("Rolle absteigend")
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { type: "is-primary" },
              on: {
                click: function($event) {
                  return _vm.openEditorModal(null)
                }
              }
            },
            [_vm._v("Neuen Benutzer anlegen")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.users,
            paginated: _vm.total > _vm.perPage,
            "backend-pagination": "",
            "backend-sorting": "",
            "backend-filtering": "",
            total: _vm.total,
            "per-page": _vm.perPage,
            "current-page": _vm.currentPage,
            "aria-next-label": "Next page",
            "aria-previous-label": "Previous page",
            "aria-page-label": "Page",
            "aria-current-label": "Current page",
            "custom-row-key": "id",
            loading: _vm.isLoading
          },
          on: { "page-change": _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { field: "name", label: "Name" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.name) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { field: "email", label: "Email" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.email) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isSuperadmin
                    ? [
                        _c(
                          "b-table-column",
                          { attrs: { field: "location", label: "Standort" } },
                          [
                            props.row.location
                              ? [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(props.row.location.name) +
                                      "\n                    "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "b-table-column",
                          { attrs: { field: "role", label: "Rolle" } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.roles[0].name) +
                                "\n                "
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      staticClass: "has-text-right",
                      attrs: { field: "id", label: "Aktionen" }
                    },
                    [
                      _c("b-button", {
                        attrs: { "icon-right": "edit" },
                        on: {
                          click: function($event) {
                            return _vm.openEditorModal(props.row.id)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.userId !== props.row.id
                        ? _c("b-button", {
                            attrs: { type: "is-danger", "icon-right": "trash" },
                            on: {
                              click: function($event) {
                                return _vm.openConfirmDeleteDialog(props.row.id)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "table--empty" }, [
              _c("p", [_vm._v("Keine Benutzer gefunden.")])
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$root.$data.loadingIndicator,
              expression: "!this.$root.$data.loadingIndicator"
            }
          ],
          attrs: {
            active: _vm.isUserEditorModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isUserEditorModalActive = $event
            }
          }
        },
        [
          _c("user-editor", {
            attrs: { "user-id": _vm.selectedUser },
            on: { reloadUserList: _vm.loadData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }